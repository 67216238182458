import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, combineLatest, finalize, map, startWith, switchMap } from 'rxjs';
import { UserBasicInfo } from 'src/app/models/user/user-models';
import { UserService } from '../user.service';

@UntilDestroy()
@Component({
	selector: 'app-users-by-rank-all',
	templateUrl: './users-by-rank-all.component.html',
	styleUrls: ['./users-by-rank-all.component.less']
})
export class UsersByRankAllComponent implements OnInit {

	loadingList = true;
	list: UserBasicInfo[] = [];
	currentUser?: UserBasicInfo;
	currentUserIndex: number = -1;
	
	pageSize = 3;
	totalRecords = 0;
	currentPage = 1;

	refresher: Subject<void> = new Subject<void>();
	refresher$ = this.refresher.asObservable();

	constructor(private readonly _userServices: UserService) {

	}

	fullName(user: UserBasicInfo): string {
		return `${user.firstName} ${user.lastName}`;
	}

	rank(rank: number): number {
		return rank + (this.currentPage-1) * this.pageSize;
	}

	page(pageIndex: number) {
		this.currentPage = pageIndex + 1;
		this.refresher.next();
	}

	ngOnInit(): void {
		this.refresher$.pipe(
			untilDestroyed(this),
			startWith({}),
			switchMap(() => {
				this.loadingList = true;
				return combineLatest([this._userServices.getUsersOrderedByByRank(this.currentPage, this.pageSize), this._userServices.basicInfo$])
					.pipe(
						untilDestroyed(this),
						finalize(() => {
							this.loadingList = false;
						})
					)
			})
		).subscribe(data => {
			if (!data[1]) {
				return;
			}
			let list = data[0].data;
			let currentUser = data[1]!;

			let currentUserIndex = list.map(e => e.id).indexOf(currentUser.id);
			if (currentUserIndex >= 0) {
				list[currentUserIndex] = currentUser;
			}

			this.list = list;
			this.currentUser = currentUser;
			this.currentUserIndex = currentUserIndex;
			this.totalRecords = data[0].totalRecords;
		});
	}
}
