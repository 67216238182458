<div class="flex flex-jc-center">
	<div class="cw-9">
		<mat-card>
			<mat-card-header>
				<mat-card-title>Тест не найден.</mat-card-title>
				<mat-card-subtitle>Для доступа к тесту, пожалуйста, пройдите по исходной ссылке.</mat-card-subtitle>
			</mat-card-header>
			<mat-card-content>
				<p class="mat-small pti-4 pbi-4">
					Пример ссылки: {{linkExample}}
				</p>
			</mat-card-content>
		</mat-card>
	</div>
</div>