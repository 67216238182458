import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs';
import { CaseService } from 'src/app/cases/case.service';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.less']
})
export class DashboardPageComponent implements OnInit {
	constructor(private readonly _userProfileService: UserService,
		private readonly _caseService: CaseService) { }
	
	loading: boolean = false;

	showArticles: boolean = false;
	showCurrentCourse: boolean = false;
	showCoursesNotStarted: boolean = false;
	showCoursesFinished: boolean = false;
	showCoursesInProgress: boolean = false;
	showRecentAchievements: boolean = false;
	showRecentDignities: boolean = false;

	ngOnInit(): void {
		this._userProfileService.refreshCurrentStoryBasicInfo();
	}

	hasData(source: string) {
		if (source == 'userstories-current') {
			this.showCurrentCourse = true;
		}
		else if (source == 'userstories-notstarted') {
			this.showCoursesNotStarted = true;
		}
		else if (source == 'userstories-inprogress') {
			this.showCoursesInProgress = true;
		}
		else if (source == 'userstories-finished') {
			this.showCoursesFinished = true;
		}
		else if (source == 'recentachievements') {
			this.showRecentAchievements = true;
		}
		else if (source == 'articles') {
			this.showArticles = true;
		}		
	}

	deleteAllUserResponses() {
		this.loading = true;
		this._caseService.deleteAllUserResponses()
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			).subscribe(() => { window.location.reload() });
	}	
}