import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, finalize, map, startWith, switchMap } from 'rxjs';
import { UsersByAchievement } from 'src/app/models/user/user-models';
import { UserService } from '../user.service';

@UntilDestroy()
@Component({
  selector: 'app-user-all-achievements',
  templateUrl: './user-all-achievements.component.html',
  styleUrls: ['./user-all-achievements.component.less']
})
export class UserAllAchievementsComponent implements OnInit {

	loadingList = true;
	list: UsersByAchievement[] = [];
	pageSize = 3;
	totalRecords = 0;
	currentPage = 1;

	refresher: Subject<void> = new Subject<void>();
	refresher$ = this.refresher.asObservable();

	constructor(private readonly _userServices: UserService) {

	}

	achievementImage(item: UsersByAchievement): string {
		return item.achievement.imageUrl ?? '/assets/achievement-image-01.svg';
	}

	page(pageIndex: number) {
		this.currentPage = pageIndex + 1;
		this.refresher.next();
	}

	ngOnInit(): void {
		this.refresher$.pipe(
			untilDestroyed(this),
			startWith({}),
			switchMap(() => {
				this.loadingList = true;
				return this._userServices.getAllAchievements(this.currentPage, this.pageSize)
					.pipe(
						untilDestroyed(this),
						finalize(() => {
							this.loadingList = false;
						})
					)
			}),
			map(response => {
				this.totalRecords = response.totalRecords;
				return response.data;
			})
		).subscribe(data => (this.list = data));
	}
}
