import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, retry } from 'rxjs';
import { ApiPagedResponse, ApiResponse } from '../models/api-response';
import { UpdateUserAddressRequest, UpdateUserAgeSegmentRequest, UpdateUserAvatarRequest, UpdateUserDateOfBirthRequest, UpdateUserEmailRequest, UpdateUserGenderRequest, UpdateUserNameRequest, UpdateUserPartialProfileRequest, UpdateUserTimeZoneRequest, User, UserBasicInfo, UserStoryBasicInfo, UsersByAchievement, UsersByDignity } from '../models/user/user-models';
import { settings } from '../shared/globals/settings';
import { OAuthService } from 'angular-oauth2-oidc';
import { ClaimTypes, JWTManager } from '../auth/identity';
import { AssessmentStageDetails } from '../models/case/case-models';

@Injectable({
	providedIn: 'root'
})
export class UserService {

	private basicInfo: BehaviorSubject<UserBasicInfo | null> = new BehaviorSubject<UserBasicInfo | null>(null);
	basicInfo$: Observable<UserBasicInfo | null> = this.basicInfo.asObservable();

	private currentStoryBasicInfo: BehaviorSubject<UserStoryBasicInfo | null> = new BehaviorSubject<UserStoryBasicInfo | null>(null);
	currentStoryBasicInfo$: Observable<UserStoryBasicInfo | null> = this.currentStoryBasicInfo.asObservable();

	constructor(
		private readonly _http: HttpClient,
		private readonly _authService: OAuthService
	) {
		
	}

	refreshBasicInfo() {
		this._http.get<ApiResponse<UserBasicInfo>>(`${settings.endpoints.gameApiRoot}/api/users/basicinfo`)
			.pipe(
				retry({ count: 5, delay: 2000 })
			)
			.subscribe(v => {
				let token = this._authService.getAccessToken();
				var claims = JWTManager.getClaimValues(token, ClaimTypes.role);
				v.data.roles = claims;
				this.basicInfo.next(v.data);
			});
	}

	refreshCurrentStoryBasicInfo() {
		this.getUserStoryBasicInfo()
			.subscribe(v => {
				this.currentStoryBasicInfo.next(v.data);
			});
	}

	public getUserStoryBasicInfo(): Observable<ApiResponse<UserStoryBasicInfo>>{
		return this._http.get<ApiResponse<UserStoryBasicInfo>>(`${settings.endpoints.gameApiRoot}/api/users/stories/current`);
	}

	public getUserAssessmentStageDetailsByStory(storyIdentityCode: string): Observable<ApiResponse<AssessmentStageDetails>> {
		return this._http.get<ApiResponse<AssessmentStageDetails>>(`${settings.endpoints.gameApiRoot}/api/users/stories/${storyIdentityCode}/assessmentdetails`);
	}	

	public getRecentAchievements(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UsersByAchievement>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UsersByAchievement>>(`${settings.endpoints.gameApiRoot}/api/users/achievements/recent`, { params: params });
	}

	public getAllAchievements(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UsersByAchievement>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UsersByAchievement>>(`${settings.endpoints.gameApiRoot}/api/users/achievements/all`, { params: params });
	}

	public getRecentDignities(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UsersByDignity>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UsersByDignity>>(`${settings.endpoints.gameApiRoot}/api/users/dignities/recent`, { params: params });
	}

	public getAllDignities(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UsersByDignity>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UsersByDignity>>(`${settings.endpoints.gameApiRoot}/api/users/dignities/all`, { params: params });
	}

	public getUsersOrderedByByRank(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UserBasicInfo>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UserBasicInfo>>(`${settings.endpoints.gameApiRoot}/api/users/byrank`, { params: params });
	}

	public getStoriesFinished(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UserStoryBasicInfo>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UserStoryBasicInfo>>(`${settings.endpoints.gameApiRoot}/api/users/stories/finished`, { params: params });
	}

	public getStoriesInProgress(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UserStoryBasicInfo>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UserStoryBasicInfo>>(`${settings.endpoints.gameApiRoot}/api/users/stories/inprogress`, { params: params });
	}

	public getStoriesNotStarted(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<UserStoryBasicInfo>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<UserStoryBasicInfo>>(`${settings.endpoints.gameApiRoot}/api/users/stories/notstarted`, { params: params });
	}

	public updateUserName(request: UpdateUserNameRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/name`, request);
	}

	public updateUserTimeZone(request: UpdateUserTimeZoneRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/timezone`, request);
	}

	public updateUserAgeSegment(request: UpdateUserAgeSegmentRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/agesegment`, request);
	}

	public updateUserEmail(request: UpdateUserEmailRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/email`, request);
	}

	public requestEmailChange(): Observable<void> {
		return this._http.patch<void>(`${settings.endpoints.gameApiRoot}/api/users/requestemailchange`, null);
	}

	public checkEmailExists(email: string): Observable<ApiResponse<boolean>> {
		let params = new HttpParams()
			.set('email', email);
		return this._http.get<ApiResponse<boolean>>(`${settings.endpoints.gameApiRoot}/api/users/emailexists`, { params: params });
	}

	public updateUserAvatar(request: UpdateUserAvatarRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/avatar`, request);
	}

	public updateUserDateOfBirth(request: UpdateUserDateOfBirthRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/dateofbirth`, request);
	}

	public updateUserGender(request: UpdateUserGenderRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/gender`, request);
	}

	public updateUserAddress(request: UpdateUserAddressRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/address`, request);
	}

	public updateUserPartialProfile(request: UpdateUserPartialProfileRequest): Observable<ApiResponse<User>> {
		return this._http.patch<ApiResponse<User>>(`${settings.endpoints.gameApiRoot}/api/users/partialprofile`, request);
	}
}