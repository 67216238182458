<tui-root>
	<app-page-wrapper *ngIf="loggedIn$ | async">
		<router-outlet></router-outlet>
	</app-page-wrapper>
	<ng-container ngProjectAs="tuiOverContent">
	</ng-container>
	<ng-container ngProjectAs="tuiOverDialogs">
	</ng-container>
	<ng-container ngProjectAs="tuiOverAlerts">
	</ng-container>
	<ng-container ngProjectAs="tuiOverPortals">
	</ng-container>
	<ng-container ngProjectAs="tuiOverHints">
	</ng-container>
</tui-root>