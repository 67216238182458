import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { CaseService } from 'src/app/cases/case.service';

@Component({
  selector: 'app-accomplished-page',
  templateUrl: './accomplished-page.component.html',
  styleUrls: ['./accomplished-page.component.less']
})
export class AccomplishedPageComponent {

	loading: boolean = false;

	constructor(
		private readonly _caseService: CaseService,
		private readonly _router: Router
	) {

	}

	deleteAllUserResponses() {
		this.loading = true;
		this._caseService.deleteAllUserResponses()
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			).subscribe(
				() => {
					this._router.navigateByUrl('/stories');
				}
			);
	}
}
