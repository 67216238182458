import { Component, Input } from '@angular/core';

export type ArrowSignOrientation = "horizontal" | "vertical";

@Component({
  selector: 'app-arrow-sign',
  templateUrl: './arrow-sign.component.html',
  styleUrls: ['./arrow-sign.component.less']
})
export class ArrowSignComponent {

	@Input('orientation') orientation: ArrowSignOrientation = 'horizontal';

}
