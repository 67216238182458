import { Injectable } from '@angular/core';
import * as SignalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { OAuthService } from 'angular-oauth2-oidc';
import { settings } from '../shared/globals/settings';
import { Subject } from 'rxjs';
import { AssessmentCompleted } from './models/assessment-models';

@Injectable({
	providedIn: 'root'
})
export class AssessmentSignalingService {

	hubConnection!: HubConnection;

	private assessmentCompleted = new Subject<AssessmentCompleted>();
	assessmentCompleted$ = this.assessmentCompleted.asObservable();

	constructor(
		private readonly _authService: OAuthService
	)
	{
		this.hubConnection = new SignalR.HubConnectionBuilder()
			.withUrl(`${settings.endpoints.gameApiRoot}/hubs/assessment`, { accessTokenFactory: () => this._authService.getAccessToken() })
			.withAutomaticReconnect()
			.build();
		
		this.registerListeners();
	}

	private registerListeners() {
		this.hubConnection.on('AssessmentCompleted', (arg: AssessmentCompleted) => {
			this.assessmentCompleted.next(arg);
		});
	}

	startConnection(): Promise<void> {
		return this.hubConnection.start();
	}

	stopConnection(): Promise<void> {
		return this.hubConnection.stop();
	}
}
