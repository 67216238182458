<div class="wrapper">
	<div class="list-wrapper">
		<div class="item-wrapper" *ngFor="let item of list" [class.fade]="!item.users.length">
			<div class="image-wrapper">
				<img [src]="achievementImage(item)" class="image" />
			</div>
			<div class="details-wrapper">
				<div class="title">{{item.achievement.title}}</div>
				<div class="description">{{item.achievement.description}}</div>
			</div>
		</div>
	</div>
	<app-paginator [totalRecords]="totalRecords" [pageSize]="pageSize" [currentPage]="currentPage-1" (page)="page($event)"></app-paginator>
</div>
