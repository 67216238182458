import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthService } from 'angular-oauth2-oidc';

@UntilDestroy()
@Component({
  selector: 'app-logout-form',
  templateUrl: './logout-form.component.html',
  styleUrls: ['./logout-form.component.less']
})
export class LogoutFormComponent {

	processingRequest: boolean = false;

	constructor(
		private readonly _dialogRef: DialogRef,
		private readonly _authService: OAuthService
	) {

	}

	close() {
		this._dialogRef.close(false);
	}

	cancel() {
		this._dialogRef.close(false);
	}

	logout() {
		this.processingRequest = true;
		this._authService.revokeTokenAndLogout({
			client_id: this._authService.clientId,
			returnTo: this._authService.redirectUri
		}, true);
	}
}
