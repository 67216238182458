import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GenderEnum } from 'src/app/models/enumerations';
import { UserBasicInfo } from 'src/app/models/user/user-models';
import { settings } from 'src/app/shared/globals/settings';
import { LogoutFormComponent } from 'src/app/user/forms/logout-form/logout-form.component';
import { UserAddressFormComponent } from 'src/app/user/forms/user-address-form/user-address-form.component';
import { UserAgeSegmentFormComponent } from 'src/app/user/forms/user-agesegment-form/user-agesegment-form.component';
import { UserDateOfBirthFormComponent } from 'src/app/user/forms/user-dateofbirth-form/user-dateofbirth-form.component';
import { EmailChangeStageEnum, UserEmailFormComponent } from 'src/app/user/forms/user-email-form/user-email-form.component';
import { UserFirstNameFormComponent } from 'src/app/user/forms/user-first-name-form/user-first-name-form.component';
import { UserGenderFormComponent } from 'src/app/user/forms/user-gender-form/user-gender-form.component';
import { UserLastNameFormComponent } from 'src/app/user/forms/user-last-name-form/user-last-name-form.component';
import { UserMiddleNameFormComponent } from 'src/app/user/forms/user-middle-name-form/user-middle-name-form.component';
import { UserNameFormComponent } from 'src/app/user/forms/user-name-form/user-name-form.component';
import { UserTimeZoneFormComponent } from 'src/app/user/forms/user-timezone-form/user-timezone-form.component';
import { UserService } from 'src/app/user/user.service';

@UntilDestroy()
@Component({
	selector: 'app-user-settings-page',
	templateUrl: './user-settings-page.component.html',
	styleUrls: ['./user-settings-page.component.less']
})
export class UserSettingsPageComponent implements OnInit {

	userBasicInfo?: UserBasicInfo;

	validationCode?: string | null;

	GenderEnum = GenderEnum;

	constructor(
		private readonly _userService: UserService,
		private readonly _dialog: Dialog,
		private readonly _route: ActivatedRoute
	) {
		
	}

	get userFullName(): string {
		if (!this.userBasicInfo) {
			return '';
		}
		let result = this.userBasicInfo.firstName;
		if (this.userBasicInfo.middleName) {
			result += ' ' + this.userBasicInfo.middleName;
		}
		result += ' ' + this.userBasicInfo.lastName;
		return result;
	}

	genderDescription(gender: GenderEnum): string {
		if (gender == GenderEnum.Male) {
			return "Мужской";
		}
		if (gender == GenderEnum.Female) {
			return "Женский";
		}
		return "Не указан";
	}

	checkRoute() {
		this._route.queryParamMap.subscribe((params: ParamMap) => {
			let code = params.get("validation-code");
			this.validationCode = code;
		});

		this._route.paramMap.subscribe((params: ParamMap) => {
			let action = params.get("action");
			if (action == "email-change") {
				const dialogRef = this._dialog.open<boolean>(UserEmailFormComponent, {
					panelClass: 'update-dialog',
					data: {
						email: this.userBasicInfo?.email,
						stage: EmailChangeStageEnum.ValidationCodeReceived,
						validationCode: this.validationCode
					}
				});
			}
		});
	}

	updateFirstName() {
		const dialogRef = this._dialog.open<boolean>(UserFirstNameFormComponent, {
			panelClass: 'update-dialog',
			data: { firstName: this.userBasicInfo?.firstName, middleName: this.userBasicInfo?.middleName, lastName: this.userBasicInfo?.lastName }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();	
			}
		});
	}

	updateMiddleName() {
		const dialogRef = this._dialog.open<boolean>(UserMiddleNameFormComponent, {
			panelClass: 'update-dialog',
			data: { firstName: this.userBasicInfo?.firstName, middleName: this.userBasicInfo?.middleName, lastName: this.userBasicInfo?.lastName }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	updateLastName() {
		const dialogRef = this._dialog.open<boolean>(UserLastNameFormComponent, {
			panelClass: 'update-dialog',
			data: { firstName: this.userBasicInfo?.firstName, middleName: this.userBasicInfo?.middleName, lastName: this.userBasicInfo?.lastName }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	updateName() {
		const dialogRef = this._dialog.open<boolean>(UserNameFormComponent, {
			panelClass: 'update-dialog',
			data: { firstName: this.userBasicInfo?.firstName, middleName: this.userBasicInfo?.middleName, lastName: this.userBasicInfo?.lastName }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	updateAddress() {
		const dialogRef = this._dialog.open<boolean>(UserAddressFormComponent, {
			panelClass: 'update-dialog',
			data: { city: this.userBasicInfo?.city }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	updateEmail() {
		const dialogRef = this._dialog.open<boolean>(UserEmailFormComponent, {
			panelClass: 'update-dialog',
			data: { email: this.userBasicInfo?.email }
		});
	}

	updateTimeZone() {
		const dialogRef = this._dialog.open<boolean>(UserTimeZoneFormComponent, {
			panelClass: 'update-dialog',
			data: { timeZoneId: this.userBasicInfo?.timeZoneId }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	updateAgeSegment() {
		const dialogRef = this._dialog.open<boolean>(UserAgeSegmentFormComponent, {
			panelClass: 'update-dialog',
			data: { ageSegmentId: this.userBasicInfo?.ageSegmentId }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	updateGender() {
		const dialogRef = this._dialog.open<boolean>(UserGenderFormComponent, {
			panelClass: 'update-dialog',
			data: { genderId: this.userBasicInfo?.genderId }
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	updateDateOfBirth() {
		let data = { day: 0, month: 0, year: 0 };
		if (this.userBasicInfo?.dateOfBirth != undefined) {
			const date = new Date(this.userBasicInfo?.dateOfBirth);
			data = { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() }
		}
		
		const dialogRef = this._dialog.open<boolean>(UserDateOfBirthFormComponent, {
			panelClass: 'update-dialog',
			data: data
		});

		dialogRef.closed.pipe(untilDestroyed(this)).subscribe(v => {
			if (v) {
				this._userService.refreshBasicInfo();
			}
		});
	}

	logout() {
		const dialogRef = this._dialog.open<boolean>(LogoutFormComponent, {
			panelClass: 'update-dialog',
			autoFocus: 'app-button'
		});
	}

	changePassword() {
		window.location.href = `${settings.endpoints.authUiRoot}/password-change?return_url=${encodeURI(window.location.href)}`;
	}

	ngOnInit(): void {
		this.checkRoute();
		this._userService.basicInfo$.pipe(
			untilDestroyed(this)
		).subscribe(v => {
			if (v) {
				this.userBasicInfo = v;
			}
		})
	}
}