export enum SelectChoiceVariantsLayoutEnum {
	Horizontal = 1,
	Vertical = 2,
	Flex = 3,
	ListBox = 4
}

export enum MatchChoiceVariantsLayoutEnum {
	Horizontal = 1,
	Vertical = 2
}

export enum UserResponseStageEnum {
	Initital = 1,
	Review = 2
}

export enum CaseSelectionStatusEnum {
	Found = 1,
	Skipped = 10,
	Failed = 50
}

export enum LayoutBreakpointEnum {
	Desktop = 1,
	Mobile = 2
}

export enum CharacterTypeEnum {
	Narrator = 1,
	Player = 2,
	NPC = 3
}

export enum GenderEnum {
	Unknown = 0,
	Male = 1,
	Female = 2
}

export enum UploadFileTypeEnum {
	UserAvatar = 1
}

export enum AssessmentStageStatusEnum {
	Initial = 10,
	Started = 20,
	Completed = 30
}