import { Component } from '@angular/core';
import { UserBasicInfo } from 'src/app/models/user/user-models';
import { UserService } from '../user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, filter, finalize, forkJoin } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-users-by-rank-top',
  templateUrl: './users-by-rank-top.component.html',
  styleUrls: ['./users-by-rank-top.component.less']
})
export class UsersByRankTopComponent {

	loadingList = true;
	list: UserBasicInfo[] = [];
	currentUser?: UserBasicInfo;
	currentUserIndex: number = -1;

	constructor(private readonly _userServices: UserService) {

	}

	fullName(user: UserBasicInfo): string {
		return `${user.firstName} ${user.lastName}`;
	}

	rank(user: UserBasicInfo, rank: number): string {
		return `${rank} место, ${user.experiencePointsTotal} exp`;
	}

	ngOnInit(): void {
		this.loadingList = true;
		combineLatest([this._userServices.getUsersOrderedByByRank(1, 5), this._userServices.basicInfo$])
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.loadingList = false;
				})
			)
			.subscribe(data => {
				if (!data[1]) {
					return;
				}
				let list = data[0].data;
				let currentUser = data[1]!;

				let currentUserIndex = list.map(e => e.id).indexOf(currentUser.id);
				if (currentUserIndex >= 0) {
					list[currentUserIndex] = currentUser;
				}

				this.list = list;
				this.currentUser = currentUser;
				this.currentUserIndex = currentUserIndex;
			});
	}
	
}
