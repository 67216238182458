import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpdateUserGenderRequest, User } from 'src/app/models/user/user-models';
import { UserService } from '../../user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { SelectItem } from 'src/app/select/select.component';
import { GenderEnum } from 'src/app/models/enumerations';

@UntilDestroy()
@Component({
	selector: 'app-user-gender-form',
	templateUrl: './user-gender-form.component.html',
	styleUrls: ['./user-gender-form.component.less']
})
export class UserGenderFormComponent {

	processingRequest: boolean = false;

	list: SelectItem[] = [];
	selectedItem?: SelectItem;
	selectedId: GenderEnum;

	readonly form = new FormGroup({
		genderId: new FormControl<GenderEnum>(GenderEnum.Unknown, [Validators.required])
	});

	constructor(
		private readonly _dialogRef: DialogRef,
		@Inject(DIALOG_DATA) _dialogData: UpdateUserGenderRequest,
		private readonly _userService: UserService
	) {
		this.selectedId = _dialogData?.genderId;
		this.list.push({ id: GenderEnum.Unknown, value: "Не указан" });
		this.list.push({ id: GenderEnum.Male, value: "Мужской" });
		this.list.push({ id: GenderEnum.Female, value: "Женский" });
	}

	close() {
		this._dialogRef.close(false);
	}

	listReady(list: SelectItem[]) {
		this.form.controls.genderId.setValue(this.selectedId)
	}

	submit() {
		if (!this.form.valid || this.processingRequest) {
			return;
		}
		this.processingRequest = true;
		this._userService.updateUserGender(this.form.value as UpdateUserGenderRequest)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: (v: ApiResponse<User>) => {
					this._dialogRef.close(true);
				}
			})
	}
}