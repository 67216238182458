import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

interface Page {
	pageNum: number | null,
	isCurrent: boolean
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.less']
})
export class PaginatorComponent {

	@Input() totalRecords!: number;
	@Input() pageSize!: number;
	@Input() currentPage: number = 0;
	@Output() page: EventEmitter<number> = new EventEmitter<number>();

	pages: Page[] = [];

	constructor() {
		
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['totalRecords'] || changes['pageSize'] || changes['currentPage']) {
			this.construct();
		}
	}

	construct() {
		if (this.totalRecords <= 0 || (this.pageSize <= 0)) {
			this.pages = [];
			return;
		}

		let pages: Page[] = [];
		let spaces: boolean = false;
		let totalPages = Math.ceil(this.totalRecords / this.pageSize);
		let visibleSpace = 2;

		for (let i = 0; i < totalPages; i++) {
			if (i != 0 && i != totalPages - 1 && Math.abs((this.currentPage - i)) > visibleSpace) {
				if (!spaces) {
					spaces = true;
					pages.push({
						pageNum: null,
						isCurrent: false
					});	
				}
				else {
					continue;
				}
			}
			else {
				spaces = false;
				pages.push({
					pageNum: i,
					isCurrent: this.currentPage == i
				});
			}
		}
		this.pages = pages;
	}

	click(index: number) {
		this.page.emit(index);
	}

}