<div class="wrapper" *ngIf="userBasicInfo$ | async as user">
	<ng-container *ngIf="story$ | async as story; else notfound">
		<ng-container *ngIf="assessmentStageDetails$ | async as details">
			<ng-container *ngIf="!details.startNotBeforeViolation && !details.startNotLaterViolation; else violation">
				<div *ngIf="stageId == AssessmentStageEnum.Profile" class="flex flex-jc-center">
					<div class="cw-9">
						<mat-card>
							<mat-card-header>
								<mat-card-title>Профиль</mat-card-title>
								<mat-card-subtitle>Укажите свои данные</mat-card-subtitle>
							</mat-card-header>
							<mat-card-content>
								<p class="mat-small pti-4 pbi-4">
									<ng-container *ngIf="story.consentText; else defaultConsentText">
										{{story.consentText}}
									</ng-container>
									<ng-template #defaultConsentText>
										Данные, получаемые при тесте, используются исключительно в целях усовершенствования Ваших
										программ обучения.
										Данные передаются Вашей компании-работодателю (организатору тестирования) и не сохраняются ни на
										каких иных носителях согласно ФЗ о персональных данных частных лиц.
										Приступая к тесту, Вы принимаете данные условия тестирования и работы с результатами
										тестирования.
									</ng-template>
								</p>
				
								<form [formGroup]="form" (ngSubmit)="startAssessment(user)" class="flex flex-dir-column">
									<mat-form-field appearance="outline">
										<mat-label>Фамилия</mat-label>
										<input formControlName="lastName" matInput type="text" placeholder="Введите фамилию">
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>Имя</mat-label>
										<input formControlName="firstName" matInput type="text" placeholder="Введите имя">
									</mat-form-field>
									<mat-form-field appearance="outline">
										<mat-label>Отчество</mat-label>
										<input formControlName="middleName" matInput type="text" placeholder="Введите отчество">
									</mat-form-field>
									<div class="flex flex-m-dir-column equal-proportion gapi-4">
										<mat-form-field appearance="outline" *ngIf="ageSegments$ | async as segments">
											<mat-label>Возраст</mat-label>
											<mat-select formControlName="ageSegmentId">
												<mat-option *ngFor="let segment of segments"
													[value]="segment.id">{{segment.name}}</mat-option>
											</mat-select>
										</mat-form-field>
										<mat-form-field appearance="outline">
											<mat-label>Пол</mat-label>
											<mat-select formControlName="genderId">
												<mat-option [value]="GenderEnum.Male">Мужской</mat-option>
												<mat-option [value]="GenderEnum.Female">Женский</mat-option>
											</mat-select>
										</mat-form-field>
									</div>
									<mat-form-field appearance="outline">
										<mat-label>Город</mat-label>
										<input formControlName="city" matInput type="text" placeholder="Укажите город">
									</mat-form-field>
								</form>
								<div class="cw-m-r-12 flex flex-jc-center">
									<button class="cw-m-r-12" mat-raised-button [disabled]="!form.valid" color="primary"
										(click)="startAssessment(user)">ПЕРЕЙТИ К ТЕСТУ</button>
								</div>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
				<div *ngIf="stageId == AssessmentStageEnum.Assessment">
					<app-case-page [storyIdentityCode]="story.identityCode!"></app-case-page>
				</div>
			</ng-container>
			<ng-template #violation>
				<div class="flex flex-jc-center">
					<div class="cw-9">
						<mat-card>
							<mat-card-header>
								<mat-card-title *ngIf="details.startNotLaterViolation">Регистрация для прохождения теста завершена.</mat-card-title>
								<mat-card-title *ngIf="details.startNotBeforeViolation">Регистрация для прохождения теста еще не началась.</mat-card-title>
							</mat-card-header>
							<mat-card-content>
							</mat-card-content>
						</mat-card>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</ng-container>
	
	<ng-template #notfound>
		<app-story-notfound *ngIf="!processingRequest"></app-story-notfound>
	</ng-template>

</div>