import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface SelectItem {
	id: any;
	value: string;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
	styleUrls: ['./select.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: SelectComponent
		}
	]
})
export class SelectComponent implements ControlValueAccessor {

	@Input() list: SelectItem[] = [];
	@Input() selectedItem?: SelectItem;
	@Input() disabled: boolean = false;
	@Input() readonly: boolean = false;
	touched: boolean = false;
	isExpanded: boolean = false;

	@Output() listReady: EventEmitter<SelectItem[]> = new EventEmitter<SelectItem[]>();

	ngOnChanges(changes: SimpleChanges) {
		if (changes['list']) {
			this.listReady.emit(this.list);
		}
	}

	//onChange = (value: SelectItem) => { };
	onChange = (id: any) => { };

	onTouched = () => { };

	/* writeValue(value: SelectItem): void {
		this.selectedItem = value;
	} */
	writeValue(id: any): void {
		if (id == undefined) {
			this.selectedItem = undefined;
			return;
		}
		let index = this.list.map(e => e.id).indexOf(id);
		if (index >= 0) {
			this.selectedItem = this.list[index];
		}
		else {
			this.selectedItem = {id: id, value: `Item [${id}]`};
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	valueChanged(item: SelectItem): boolean {
		if (this.readonly) {
			return false;
		}
		if (!this.disabled) {
			this.selectedItem = item;
			this.toggle();
			this.markAsTouched();
			//this.onChange(this.selectedItem);
			this.onChange(this.selectedItem.id);
		}
		return true;
	}

	markAsTouched() {
		if (!this.touched) {
			this.touched = true;
			this.onTouched();
		}
	}

	toggle() {
		this.isExpanded = !this.isExpanded;
	}

	blur() {
		this.isExpanded = false;
	}
}