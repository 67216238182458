import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription, finalize, interval } from 'rxjs';
import { AssessmentStageDetails } from 'src/app/models/case/case-models';
import { CaseInteractionService } from '../case-interaction.service';
import { CaseService } from '../case.service';

@UntilDestroy()
@Component({
	selector: 'app-assessment-progress',
	templateUrl: './assessment-progress.component.html',
	styleUrls: ['./assessment-progress.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentProgressComponent {

	@Input() details!: AssessmentStageDetails;

	private secondsLeft?: number;
	public timeLimit: boolean = false;

	private timer?: Subscription;

	processingRequest: boolean = false;

	constructor(
		private readonly _changeDetector: ChangeDetectorRef,
		private readonly _caseService: CaseService
	) {
		
	}

	private secondsToTime(totalSeconds: number) {
		const totalMinutes = Math.floor(totalSeconds / 60);

		const seconds = totalSeconds % 60;
		const hours = Math.floor(totalMinutes / 60);
		const minutes = totalMinutes % 60;

		return { h: hours, m: minutes, s: seconds };
	}

	private secondsToTimeString(totalSeconds: number): string {
		const totalMs = totalSeconds * 1000;
		const result = new Date(totalMs).toISOString().slice(11, 19);

		return result;
	}

	ngOnChanges(changes: SimpleChanges) {

		if (changes['details']) {
			if (this.details.secondsLeft != undefined && this.details.secondsLeft > 0) {
				this.timeLimit = true;
				this.secondsLeft = this.details.secondsLeft;
				
				if (this.timer) {
					this.timer.unsubscribe();	
				}
				this.timer = interval(1000).pipe(untilDestroyed(this)).subscribe(() => {
					if (this.secondsLeft != undefined) {
						if (this.secondsLeft >= 1) {
							this.secondsLeft--;
							this._changeDetector.markForCheck();
						}
						else {
							this.secondsLeft = 0;
							this.timer!.unsubscribe();
							this.ensureCompleted();
						}
					}
				});
			}
			else if (this.details.secondsLeft != undefined && this.details.secondsLeft <= 0) {
				this.timeLimit = true;
				this.secondsLeft = 0;
				this.ensureCompleted();
			}
		}
	}

	get timeLeft(): string | null {
		if (this.secondsLeft != undefined) {
			return this.secondsToTimeString(this.secondsLeft);
		}
		else {
			return null
		}
	}

	get secondsLeftProp(): number {
		return this.secondsLeft!;
	}

	ensureCompleted() {
		if (this.processingRequest) {
			return;
		}

		this.processingRequest = true;

		this._caseService.ensureAssessmentCompleted(this.details.chapter.id)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: () => {
					
				}
			})	
	}
}