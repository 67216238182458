import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserBasicInfo } from 'src/app/models/user/user-models';
import { UserService } from 'src/app/user/user.service';

@UntilDestroy()
@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.less']
})
export class ProfilePageComponent implements OnInit {
	
	userBasicInfo?: UserBasicInfo;

	showRecentAchievements: boolean = false;
	showRecentDignities: boolean = false;

	constructor(
		private readonly _userService: UserService
	) {
		
	}
	
	ngOnInit(): void {
		this._userService.basicInfo$.pipe(
			untilDestroyed(this)
		).subscribe(v => {
			if (v) {
				this.userBasicInfo = v;
			}
		})
	}

	hasData(source: string) {
		if (source == 'recentachievements') {
			this.showRecentAchievements = true;
		}
		else if (source == 'recentdignities') {
			this.showRecentDignities = true;
		}

	}

}
