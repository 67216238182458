import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../user.service';
import { DialogRef } from '@angular/cdk/dialog';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateUserAddressRequest, UpdateUserNameRequest, User } from 'src/app/models/user/user-models';
import { finalize } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';

@UntilDestroy()
@Component({
	selector: 'app-user-address-form',
	templateUrl: './user-address-form.component.html',
	styleUrls: ['./user-address-form.component.less']
})
export class UserAddressFormComponent {

	processingRequest: boolean = false;

	readonly form = new FormGroup({
		city: new FormControl<string | null>(null, [Validators.required])
	});

	constructor(
		private readonly _dialogRef: DialogRef,
		@Inject(DIALOG_DATA) _dialogData: UpdateUserAddressRequest,
		private readonly _userService: UserService
	) {
		this.form.patchValue(_dialogData);
	}

	close() {
		this._dialogRef.close(false);
	}

	submit() {
		if (!this.form.valid || this.processingRequest) {
			return;
		}
		this.processingRequest = true;
		this._userService.updateUserAddress(this.form.value as UpdateUserAddressRequest)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: (v: ApiResponse<User>) => {
					this._dialogRef.close(true);
				}
			})
	}
}