<div class="wrapper">
	<div *ngFor="let p of pages; let index = index" class="item-wrapper" [class.current]="p.isCurrent" [class.spaces]="p.pageNum == null" (click)="click(index)">
		<div class="item" *ngIf="p.pageNum != null; else spaces;">
			{{p.pageNum+1}}
		</div>
		<ng-template #spaces>
			<div class="item"></div>
			<div class="item"></div>
			<div class="item"></div>
		</ng-template>
	</div>
</div>
