import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ConversationCase } from 'src/app/models/case/case-models';
import { CaseInteractionService } from '../case-interaction.service';
import { CaseService } from '../case.service';
import { CreateConversationUserResponseRequest, UserResponse } from 'src/app/models/user-response/user-response';
import { CaseTypeEnum } from 'src/app/models/discriminators';
import { catchError, finalize, throwError } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-conversation-case-form',
	templateUrl: './conversation-case-form.component.html',
	styleUrls: ['./conversation-case-form.component.less']
})
export class ConversationCaseFormComponent implements OnInit {

	processingRequest: boolean = false;

	@Input('case') case!: ConversationCase;

	constructor(
		private readonly _caseService: CaseService,
		private readonly _interractionService: CaseInteractionService
	) { }

	ngOnInit(): void {
		this.onCase();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['case'] && !changes['case'].firstChange) {
			this.onCase();
		}
	}

	onCase() {
		
	}

	get moveNextButtonText(): string {
		return "Дальше";
	}

	submit() {
		this.processingRequest = true;
		this._interractionService.userResponseSending.next();

		let request: CreateConversationUserResponseRequest = { caseTypeId: CaseTypeEnum.ConversationCase, caseId: this.case.id };

		this._caseService.createUserResponse(request)
			.pipe(
				untilDestroyed(this),
				catchError((e) => {
					this._interractionService.userResponseFailed.next();
					return throwError(() => e);
				}),
				finalize(() => {
					this.processingRequest = false;
					this._interractionService.userResponseProcessed.next();
				})
			)
			.subscribe({
				next: (v: ApiResponse<UserResponse>) => {
					this._interractionService.userResponseSucceeded.next();
				}
			})
	}
}