import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Case } from '../models/case/case-models';
import { AssessmentStageChapter } from '../models/chapter/chapter-models';

@Injectable({
  providedIn: 'root'
})
export class CaseInteractionService {

	userResponseSending: Subject<void> = new Subject<void>();
	userResponseSending$: Observable<void> = this.userResponseSending.asObservable();

	userResponseProcessed: Subject<void> = new Subject<void>();
	userResponseProcessed$: Observable<void> = this.userResponseProcessed.asObservable();

	userResponseSucceeded: Subject<void> = new Subject<void>();
	userResponseSucceeded$: Observable<void> = this.userResponseSucceeded.asObservable();

	userResponseFailed: Subject<void> = new Subject<void>();
	userResponseFailed$: Observable<void> = this.userResponseFailed.asObservable();

	userResponseReviewCompleted: Subject<void> = new Subject<void>();
	userResponseReviewCompleted$: Observable<void> = this.userResponseReviewCompleted.asObservable();

	caseReceived: Subject<Case> = new Subject<Case>();
	caseReceived$: Observable<Case> = this.caseReceived.asObservable();

	assessmentStarted: Subject<AssessmentStageChapter> = new Subject<AssessmentStageChapter>();
	assessmentStarted$: Observable<AssessmentStageChapter> = this.assessmentStarted.asObservable();

	constructor() { }
	
}
