import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from '../user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserStoryBasicInfo } from 'src/app/models/user/user-models';

@UntilDestroy()
@Component({
  selector: 'app-user-current-story',
  templateUrl: './user-current-story.component.html',
  styleUrls: ['./user-current-story.component.less']
})
export class UserCurrentStoryComponent implements OnInit {

	currentStory?: UserStoryBasicInfo;

	@Output() hasData: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private readonly _userProfileService: UserService
	) {

	}

	get progress(): number {
		if (!this.currentStory) {
			return 0;
		}
		else if (this.currentStory.checkpointsTotal == 0) {
			return 100;
		}
		else {
			return Math.min(100, 100 * (this.currentStory.checkpointsPassed / this.currentStory.checkpointsTotal));
		}
	}

	ngOnInit(): void {
		this._userProfileService.currentStoryBasicInfo$.pipe(
			untilDestroyed(this)
		).subscribe(v => {
			if (v) {
				this.currentStory = v;
				this.hasData.emit(`userstories-current`);
			}
		})
	}
}
