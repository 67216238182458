import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, delay, filter, finalize, map, of, switchMap, tap } from 'rxjs';
import { AssessmentStageDetails } from 'src/app/models/case/case-models';
import { GenderEnum } from 'src/app/models/enumerations';
import { Story } from 'src/app/models/story/story-models';
import { AgeSegment } from 'src/app/models/system/system-models';
import { UpdateUserPartialProfileRequest, UserBasicInfo } from 'src/app/models/user/user-models';
import { StoryService } from 'src/app/stories/story.service';
import { SystemService } from 'src/app/system/system.service';
import { UserService } from 'src/app/user/user.service';

const assessmentStageLocalStorageName = "assessment_stage";

enum AssessmentStageEnum {
	Undefined = 0,
	Profile = 1,
	Assessment = 2
}

interface AssessmentStage {
	userId: string;
	stageId: AssessmentStageEnum;
}

@Component({
	selector: 'app-default-page',
	templateUrl: './default-page.component.html',
	styleUrls: ['./default-page.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultPageComponent implements OnInit {

	processingRequest: boolean = true;

	userBasicInfo$?: Observable<UserBasicInfo | null>;
	ageSegments$?: Observable<AgeSegment[]>;

	stageId: AssessmentStageEnum = AssessmentStageEnum.Undefined;
	AssessmentStageEnum = AssessmentStageEnum;
	GenderEnum = GenderEnum;

	private storyIdentityCode$!: Observable<string | null>;
	story$!: Observable<Story | null>;

	assessmentStageDetails$!: Observable<AssessmentStageDetails | null>;

	readonly form = new FormGroup({
		firstName: new FormControl<string | null>(null, [Validators.required]),
		middleName: new FormControl<string | null>(null),
		lastName: new FormControl<string | null>(null, [Validators.required]),
		genderId: new FormControl<GenderEnum | null>(GenderEnum.Unknown, [Validators.required]),
		ageSegmentId: new FormControl<number | null>(null, [Validators.required]),
		city: new FormControl<string | null>(null, [Validators.required])
	});
	
	constructor(
		private readonly _userService: UserService,
		private readonly _storyService: StoryService,
		private readonly _systemService: SystemService,
		private readonly _route: ActivatedRoute,
		private readonly _changeDetector: ChangeDetectorRef
	) {
		this.storyIdentityCode$ = this._route.paramMap
			.pipe(
				tap(() => {
					this.processingRequest = true;
				}),
				map((params: ParamMap) => {
					return params.get("storyIdentityCode");
				}),
				switchMap(v => {
					if (v) {
						return of(v);
					}
					return this._userService.getUserStoryBasicInfo().pipe(
						map(v => {
							return v?.data?.storyIdentityCode ?? null;
						})
					);
				})
			);
			
		this.story$ = this.storyIdentityCode$
			.pipe(
				switchMap(v => {
					if (!v) {
						this.processingRequest = false;
						return of(null);
					}
					this.assessmentStageDetails$ = this._userService.getUserAssessmentStageDetailsByStory(v).pipe(
						map(v => v.data)
					);
					return this._storyService.getStoryByIdentityCode(v!).pipe(
						map(v => {
							return v?.data ?? null;
						}),
						finalize(() => {
							this.processingRequest = false;
						})
					);
				})
			);
	}

	startButtonTooltip(user: UserBasicInfo): string {
		if (this.form.valid) {
			return "Перейти к тестированию";
		}
		else {
			return "Необходимо заполнить профиль";
		}
	}

	getCurrentStage(user: UserBasicInfo) {
		const stagesString = localStorage.getItem(assessmentStageLocalStorageName);
		if (stagesString) {
			const stageList: AssessmentStage[] = JSON.parse(stagesString);
			const index = stageList.map(e => e.userId).indexOf(user.id);
			if (index != -1) {
				this.stageId = stageList[index].stageId;
			}
			else {
				this.setCurrentStage({ userId: user.id, stageId: AssessmentStageEnum.Profile });
			}
		}
		else {
			this.setCurrentStage({ userId: user.id, stageId: AssessmentStageEnum.Profile });
		}
	}

	setCurrentStage(stage: AssessmentStage) {
		this.stageId = stage.stageId;
		const stagesString = localStorage.getItem(assessmentStageLocalStorageName);
		if (stagesString) {
			const stageList: AssessmentStage[] = JSON.parse(stagesString);
			const index = stageList.map(e => e.userId).indexOf(stage.userId);
			if (index != -1) {
				stageList[index].stageId = stage.stageId;
			}
			else {
				stageList.push(stage);
			}
			localStorage.setItem(assessmentStageLocalStorageName, JSON.stringify(stageList));
		}
		else {
			let stageList: AssessmentStage[] = [stage];
			localStorage.setItem(assessmentStageLocalStorageName, JSON.stringify(stageList));
		}
		this._changeDetector.markForCheck();
	}

	startAssessment(user: UserBasicInfo) {
		
		if (!this.form.valid || this.processingRequest) {
			return;
		}

		this._userService.updateUserPartialProfile(this.form.value as UpdateUserPartialProfileRequest).pipe(
			finalize(() => {
				this.processingRequest = false;
			})
		).subscribe(() => {
			this.setCurrentStage({ userId: user.id, stageId: AssessmentStageEnum.Assessment });
		});
	}

	ngOnInit() {
		this.userBasicInfo$ = this._userService.basicInfo$.pipe(
			filter(v => v != null),
			tap(v => {
				this.getCurrentStage(v!);
				this.form.patchValue(v as UpdateUserPartialProfileRequest);
			})
		);
		
		this.ageSegments$ = this._systemService.getAllAgeSegments().pipe(
			map(v => v.data)
		);
	}
}