<mat-card class="wrapper" class="cw-12 cw-m-r-12">
	<mat-card-content>
		<ng-container *ngIf="caseDetails.assessmentStageDetails?.statusId == AssessmentStageStatusEnum.Started">
			<div class="progress-wrapper" class="pbi-2">
				<app-assessment-progress [details]="caseDetails.assessmentStageDetails"></app-assessment-progress>
			</div>
		</ng-container>
		<ng-container *ngIf="caseDetails.case">
			<div class="case-phrases-wrapper" *ngIf="caseDetails.case.phrases.length > 0">
				<app-case-phrase-list [case]="caseDetails.case"></app-case-phrase-list>
			</div>
			<div *ngIf="caseDetails.case.caseTypeId != CaseTypeEnum.ConversationCase" class="case-header mbi-8">
				<div class="mat-h2" *ngIf="caseDetails.case.title">{{caseDetails.case.title}}</div>
				<div class="tui-text_body-m" *ngIf="caseDetails.case.text">{{caseDetails.case.text}}</div>
			</div>
			<div *ngIf="caseDetails.case.caseTypeId == CaseTypeEnum.SelectChoiceVariantCase" class="form-wrapper">
				<app-select-case-form [case]="asSelectChoiceVariantCase"></app-select-case-form>
			</div>
			<div *ngIf="caseDetails.case.caseTypeId == CaseTypeEnum.MatchChoiceVariantCase" class="form-wrapper">
				<app-match-case-form [case]="asMatchChoiceVariantCase"></app-match-case-form>
			</div>
			<div *ngIf="caseDetails.case.caseTypeId == CaseTypeEnum.WrittenChoiceCase" class="form-wrapper">
				<app-written-case-form [case]="asWrittenChoiceCase"></app-written-case-form>
			</div>
			<div *ngIf="caseDetails.case.caseTypeId == CaseTypeEnum.ConversationCase" class="form-wrapper">
				<app-conversation-case-form [case]="asConversationCase"></app-conversation-case-form>
			</div>
		</ng-container>
		<ng-container *ngIf="caseDetails.assessmentStageDetails?.statusId == AssessmentStageStatusEnum.Initial">
			<app-assessment-introduction [chapter]="caseDetails.assessmentStageDetails.chapter"></app-assessment-introduction>
		</ng-container>
		<ng-container *ngIf="caseDetails.assessmentStageDetails?.statusId == AssessmentStageStatusEnum.Completed">
			<app-assessment-completion [chapter]="caseDetails.assessmentStageDetails.chapter"></app-assessment-completion>
		</ng-container>
	</mat-card-content>
</mat-card>