<div class="wrapper" [attr.style]="backgroundImageUrl$ | async" [class.dark]="!disableBackgroundImage" [class.disable-bg-image]="disableBackgroundImage">
	<mat-toolbar class="header-wrapper">
		<img src="/assets/logo-icon-01.png" class="logo"/>
		<div class="flex-grow"></div>
		<app-login></app-login>
	</mat-toolbar>
	<div class="body-wrapper">
		<ng-content></ng-content>
	</div>
	<mat-toolbar class="footer-wrapper">
		<div class="cwi-36"></div>
		<div class="text">&copy; Kompass 2023. All rights reserved.</div>
		<a class="cwi-36" mat-stroked-button href="https://t.me/kompass_support" target="_blank"
			matTooltip="Написать в Telegram">
			Поддержка
		</a>
	</mat-toolbar>
</div>