import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { settings } from '../shared/globals/settings';
import { Story } from '../models/story/story-models';

@Injectable({
	providedIn: 'root'
})
export class StoryService {

	constructor(
		private readonly _http: HttpClient
	) { }

	public getStoryByIdentityCode(storyIdentityCode: string): Observable<ApiResponse<Story>> {
		return this._http.get<ApiResponse<Story>>(`${settings.endpoints.gameApiRoot}/api/stories/${storyIdentityCode}`);
	}
}