import { AuthConfig, OAuthModuleConfig } from "angular-oauth2-oidc";
import { settings } from "../shared/globals/settings";

export const authConfig: AuthConfig = {
	issuer: settings.endpoints.authRoot,
	redirectUri: window.location.origin + window.location.pathname,
	//postLogoutRedirectUri: window.location.origin + window.location.pathname,
	//postLogoutRedirectUri: settings.endpoints.authRoot,
	clientId: 'assessment.ui',
	responseType: 'code',
	scope: 'openid profile offline_access game.play',
	showDebugInformation: true,
};

export const authModuleConfig: OAuthModuleConfig = {
	resourceServer: {
		allowedUrls: [settings.endpoints.authRoot, settings.endpoints.gameApiRoot],
		sendAccessToken: true,
	}
};