import { Component, Input, SimpleChanges } from '@angular/core';
import { UserBasicInfo } from 'src/app/models/user/user-models';

export type AvatarSize = 'l' | 'm' | 's' | 'xs';

@Component({
	selector: 'app-user-avatar',
	templateUrl: './user-avatar.component.html',
	styleUrls: ['./user-avatar.component.less']
})
export class UserAvatarComponent {
	
	@Input() userBasicInfo!: UserBasicInfo;
	@Input() hasProgress: boolean = false;
	@Input() size: AvatarSize = 's';

	progressRadius: number = 84;
	progressRingStrokeWidth: number = 8;
	progressInnerStrokeWidth: number = 4;

	constructor() { 
		
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['size']) {
			if (this.size == 'xs') {
				this.progressRadius = 22;
				this.progressRingStrokeWidth = 2.1;
				this.progressInnerStrokeWidth = 0.88;
			}
			else if (this.size == 's') {
				this.progressRadius = 26;
				this.progressRingStrokeWidth = 2.48;
				this.progressInnerStrokeWidth = 1.24;
			}
			else if (this.size == 'l') {
				this.progressRadius = 84;
				this.progressRingStrokeWidth = 8;
				this.progressInnerStrokeWidth = 4;
			}
		}
	}

	get initials(): string {
		return this.userBasicInfo.firstName.charAt(0) + " " + this.userBasicInfo.lastName.charAt(0);
	}

	get userFullName(): string {
		if (!this.userBasicInfo) {
			return '';
		}
		let result = this.userBasicInfo.firstName;
		if (this.userBasicInfo.middleName) {
			result += ' ' + this.userBasicInfo.middleName;
		}
		result += ' ' + this.userBasicInfo.lastName;
		return result;
	}

	get progress(): number {
		if (this.userBasicInfo.experiencePointsOnCurrentLevel && this.userBasicInfo.experiencePointsLeftToLevelUp) {
			if (this.userBasicInfo.experiencePointsLeftToLevelUp == 0) {
				return 0;
			}
			return 100 * this.userBasicInfo.experiencePointsOnCurrentLevel / (this.userBasicInfo.experiencePointsOnCurrentLevel + this.userBasicInfo.experiencePointsLeftToLevelUp);
		}
		return 0;
	}
}
