import { Component } from '@angular/core';

@Component({
  selector: 'app-user-dignities-page',
  templateUrl: './user-dignities-page.component.html',
  styleUrls: ['./user-dignities-page.component.less']
})
export class UserDignitiesPageComponent {

}
