<div class="wrapper">
	<div class="inner-wrapper" *ngIf="userBasicInfo">
		<div class="item-wrapper">
			<div class="title">ФИО пользователя</div>
			<div class="info-wrapper">
				<div class="field">{{userFullName}}</div>
				<app-button appearance="outline" size="s" (click)="updateName()">Редактировать</app-button>
			</div>
		</div>
		<div class="item-wrapper">
			<div class="title">Возраст</div>
			<div class="info-wrapper">
				<div class="field" [class.no-info]="!userBasicInfo.ageSegmentName">
					{{userBasicInfo.ageSegmentName ?? 'Не указан'}}</div>
				<app-button appearance="outline" size="s" (click)="updateAgeSegment()">Редактировать</app-button>
			</div>
		</div>
		<div class="item-wrapper">
			<div class="title">Пол</div>
			<div class="info-wrapper">
				<div class="field" [class.no-info]="userBasicInfo.genderId == GenderEnum.Unknown">
					{{genderDescription(userBasicInfo.genderId)}}
				</div>
				<app-button appearance="outline" size="s" (click)="updateGender()">Редактировать</app-button>
			</div>
		</div>
		<div class="item-wrapper">
			<div class="title">Город</div>
			<div class="info-wrapper">
				<div class="field" [class.no-info]="!userBasicInfo.city">
					{{userBasicInfo.city ?? 'Не указан'}}
				</div>
				<app-button appearance="outline" size="s" (click)="updateAddress()">Редактировать</app-button>
			</div>
		</div>
	</div>
</div>