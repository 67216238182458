<div class="wrapper" [class.expanded]="isExpanded" (blur)="blur()" tabindex=0>
	<div class="selected-wrapper" (click)="toggle()">
		<div class="selected-text" *ngIf="selectedItem">
			{{selectedItem.value}}
		</div>
		<div class="space"></div>
		<div class="anchor"></div>
	</div>
	<div class="list-wrapper">
		<div class="inner-wrapper">
			<div class="list-item" *ngFor="let item of list" (click)="valueChanged(item)">
				{{item.value}}
			</div>
		</div>
	</div>
</div>
