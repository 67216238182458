import { LOCALE_ID, NgModule } from '@angular/core';
import '@angular/common/locales/global/ru';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { TuiAlertModule, TuiRootModule } from '@taiga-ui/core';
import { TUI_SANITIZER } from '@taiga-ui/core';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import { ErrorsInterceptor } from './shared/interceptors/errors.interceptor';
import { PageWrapperComponent } from './pages/page-wrapper/page-wrapper.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { LayoutModule } from '@angular/cdk/layout';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { LoginComponent } from './login/login.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ButtonComponent } from './button/button.component';
import { LoaderComponent } from './loader/loader.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { UserAvatarComponent } from './user/user-avatar/user-avatar.component';
import { CasePageComponent } from './pages/case-page/case-page.component';
import { CaseFormComponent } from './cases/case-form/case-form.component';
import { SelectCaseFormComponent } from './cases/select-case-form/select-case-form.component';
import { WrittenCaseFormComponent } from './cases/written-case-form/written-case-form.component';
import { MatchCaseFormComponent } from './cases/match-case-form/match-case-form.component';
import { ArrowSignComponent } from './arrow-sign/arrow-sign.component';
import { AccomplishedPageComponent } from './pages/accomplished-page/accomplished-page.component';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { ConversationCaseFormComponent } from './cases/conversation-case-form/conversation-case-form.component';
import { CasePhraseListComponent } from './cases/case-phrase-list/case-phrase-list.component';
import { ArcProgressComponent } from './arc-progress/arc-progress.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { UserBasicInfoComponent } from './user/user-basic-info/user-basic-info.component';
import { BackButtonDirective } from './shared/directives/back-button.directive';
import { UserRecentAchievementsComponent } from './user/user-recent-achievements/user-recent-achievements.component';
import { UserSettingsPageComponent } from './pages/user-settings-page/user-settings-page.component';
import { UserAchievementsPageComponent } from './pages/user-achievements-page/user-achievements-page.component';
import { UserDignitiesPageComponent } from './pages/user-dignities-page/user-dignities-page.component';
import { UserAllAchievementsComponent } from './user/user-all-achievements/user-all-achievements.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { UserAllDignitiesComponent } from './user/user-all-dignities/user-all-dignities.component';
import { UserRecentDignitiesComponent } from './user/user-recent-dignities/user-recent-dignities.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';
import { UserCurrentStoryComponent } from './user/user-current-story/user-current-story.component';
import { UsersByRankPageComponent } from './pages/users-by-rank-page/users-by-rank-page.component';
import { UsersByRankAllComponent } from './user/users-by-rank-all/users-by-rank-all.component';
import { UsersByRankTopComponent } from './user/users-by-rank-top/users-by-rank-top.component';
import { SpacedNumberPipe } from './shared/pipes/spaced-number.pipe';
import { UserStoriesComponent } from './user/user-stories/user-stories.component';
import { UserStoriesPageComponent } from './pages/user-stories-page/user-stories-page.component';
import { UserFirstNameFormComponent } from './user/forms/user-first-name-form/user-first-name-form.component';
import { UserLastNameFormComponent } from './user/forms/user-last-name-form/user-last-name-form.component';
import { LogoutFormComponent } from './user/forms/logout-form/logout-form.component';
import { SelectComponent } from './select/select.component';
import { UserTimeZoneFormComponent } from './user/forms/user-timezone-form/user-timezone-form.component';
import { UserEmailFormComponent } from './user/forms/user-email-form/user-email-form.component';
import { ValErrorComponent } from './val-error/val-error.component';
import { ArticleSliderComponent } from './content/article-slider/article-slider.component';
import { ArticlePageSliderComponent } from './content/article-page-slider/article-page-slider.component';
import { UploadComponent } from './upload/upload.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig, authModuleConfig } from './auth/auth.config';
import { AssessmentIntroductionComponent } from './cases/assessment-introduction/assessment-introduction.component';
import { AssessmentCompletionComponent } from './cases/assessment-completion/assessment-completion.component';
import { AssessmentProgressComponent } from './cases/assessment-progress/assessment-progress.component';
import { UserNameFormComponent } from './user/forms/user-name-form/user-name-form.component';
import { UserMiddleNameFormComponent } from './user/forms/user-middle-name-form/user-middle-name-form.component';
import { UserDateOfBirthFormComponent } from './user/forms/user-dateofbirth-form/user-dateofbirth-form.component';
import { UserGenderFormComponent } from './user/forms/user-gender-form/user-gender-form.component';
import { UserAgeSegmentFormComponent } from './user/forms/user-agesegment-form/user-agesegment-form.component';
import { UserAddressFormComponent } from './user/forms/user-address-form/user-address-form.component';
import { DefaultPageComponent } from './pages/default-page/default-page.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { NotFoundPageComponent } from './pages/notfound-page/notfound-page.component';
import { StoryNotfoundComponent } from './stories/story-notfound/story-notfound.component';

@NgModule({
	declarations: [
		AppComponent,
		PageWrapperComponent,
		ErrorPageComponent,
		UnauthorizedPageComponent,
		LoginComponent,
		ErrorMessageComponent,
		ButtonComponent,
		LoaderComponent,
		CheckboxComponent,
		UserAvatarComponent,
		CasePageComponent,
		CaseFormComponent,
		SelectCaseFormComponent,
		WrittenCaseFormComponent,
		MatchCaseFormComponent,
		ArrowSignComponent,
		AccomplishedPageComponent,
		TestPageComponent,
		ConversationCaseFormComponent,
		CasePhraseListComponent,
		ArcProgressComponent,
		ProfilePageComponent,
		UserBasicInfoComponent,
		BackButtonDirective,
		UserRecentAchievementsComponent,
		UserSettingsPageComponent,
		UserAchievementsPageComponent,
		UserDignitiesPageComponent,
		UserAllAchievementsComponent,
		PaginatorComponent,
		UserAllDignitiesComponent,
		UserRecentDignitiesComponent,
		DashboardPageComponent,
		UserCurrentStoryComponent,
		UsersByRankPageComponent,
		UsersByRankAllComponent,
		UsersByRankTopComponent,
		SpacedNumberPipe,
		UserStoriesComponent,
		UserStoriesPageComponent,
		UserFirstNameFormComponent,
		UserLastNameFormComponent,
		LogoutFormComponent,
		SelectComponent,
		UserTimeZoneFormComponent,
		UserEmailFormComponent,
		ValErrorComponent,
		ArticleSliderComponent,
		ArticlePageSliderComponent,
		UploadComponent,
		AssessmentIntroductionComponent,
		AssessmentCompletionComponent,
		AssessmentProgressComponent,
		UserNameFormComponent,
		UserMiddleNameFormComponent,
		UserDateOfBirthFormComponent,
		UserGenderFormComponent,
  		UserAgeSegmentFormComponent,
    	UserAddressFormComponent,
    	DefaultPageComponent,
     NotFoundPageComponent,
     StoryNotfoundComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		TuiRootModule,
		TuiAlertModule,
		LayoutModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatDialogModule,
		MatProgressBarModule,
		OAuthModule.forRoot(),

		MatToolbarModule,
		MatButtonModule,
		MatIconModule,
		MatCheckboxModule,
		MatFormFieldModule,
		MatInputModule,
		MatRippleModule,
		MatSelectModule,
		MatCardModule
	],
	providers: [
		{ provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
		{ provide: LOCALE_ID, useValue: 'ru-RUS' },
		{ provide: OAuthStorage, useValue: localStorage },
		{ provide: AuthConfig, useValue: authConfig },
		{ provide: OAuthModuleConfig, useValue: authModuleConfig },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
