import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserService } from '../user.service';
import { UsersByDignity } from 'src/app/models/user/user-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';

@UntilDestroy()
@Component({
	selector: 'app-user-recent-dignities',
	templateUrl: './user-recent-dignities.component.html',
	styleUrls: ['./user-recent-dignities.component.less']
})
export class UserRecentDignitiesComponent implements OnInit {

	loadingList = true;
	list: UsersByDignity[] = [];

	@Output() hasData: EventEmitter<string> = new EventEmitter<string>();

	constructor(private readonly _userServices: UserService) {

	}

	dignityImage(item: UsersByDignity): string {
		return item.dignity.imageUrl ?? '/assets/dignity-image-01.png';
	}

	ngOnInit(): void {
		this.loadingList = true;
		this._userServices.getRecentDignities(1, 5)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.loadingList = false;
				})
			)
			.subscribe(data => {
				this.list = data.data;
				if (this.list?.length > 0) {
					this.hasData.emit(`recentdignities`);
				}				
			});
	}
}
