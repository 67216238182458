<div class="wrapper">
	<div *ngFor="let phrase of case.phrases" class="bubble-outer-wrapper" [class.right]="phrase.character.characterTypeId == CharacterTypeEnum.Player">
		<div class="bubble-inner-wrapper">
			<img *ngIf="phrase.character.avatarUrl" class="avatar" [src]="phrase.character.avatarUrl" />
			<div class="content-wrapper">
				<div class="name" [ngStyle]="{'color': phrase.character.nameColor}">{{phrase.character.name}}</div>
				<div class="text">{{phrase.text}}</div>
				<img *ngIf="phrase.imageUrl" class="image" [src]="phrase.imageUrl" />
			</div>
		</div>
	</div>
</div>