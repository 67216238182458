import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CaseDetails, ConversationCase, MatchChoiceVariantCase, SelectChoiceVariantCase, WrittenChoiceCase } from 'src/app/models/case/case-models';
import { CaseTypeEnum } from 'src/app/models/discriminators';
import { AssessmentStageStatusEnum, UserResponseStageEnum } from 'src/app/models/enumerations';
import { CaseInteractionService } from '../case-interaction.service';

@UntilDestroy()
@Component({
	selector: 'app-case-form',
	templateUrl: './case-form.component.html',
	styleUrls: ['./case-form.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseFormComponent implements OnInit {

	@Input() caseDetails!: CaseDetails;

	CaseTypeEnum = CaseTypeEnum;
	AssessmentStageStatusEnum = AssessmentStageStatusEnum;

	constructor(
		private readonly _interractionService: CaseInteractionService,
		private readonly _changeDetector: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this._interractionService.caseReceived$.pipe(untilDestroyed(this)).subscribe(() => {
			this._changeDetector.markForCheck();
		});
	}

	get asConversationCase(): ConversationCase {
		return this.caseDetails.case as ConversationCase;
	}

	get asWrittenChoiceCase(): WrittenChoiceCase {
		return this.caseDetails.case as WrittenChoiceCase;
	}

	get asSelectChoiceVariantCase(): SelectChoiceVariantCase {
		return this.caseDetails.case as SelectChoiceVariantCase;
	}

	get asMatchChoiceVariantCase(): MatchChoiceVariantCase {
		return this.caseDetails.case as MatchChoiceVariantCase;
	}
}