import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, throwError } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { MatchChoiceVariantCase } from 'src/app/models/case/case-models';
import { MatchChoiceVariant, MatchChoiceVariantGroupEnum } from 'src/app/models/choice-variant/choice-variant-models';
import { CaseTypeEnum } from 'src/app/models/discriminators';
import { UserResponseStageEnum } from 'src/app/models/enumerations';
import { CreateMatchVariantUserResponseRequest, UserResponse, UserResponseReview } from 'src/app/models/user-response/user-response';
import { CaseInteractionService } from '../case-interaction.service';
import { CaseService } from '../case.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface GroupItem {
	variant: MatchChoiceVariant,
	selected: boolean,
	linked: boolean,
	responseReview?: UserResponseReview
}

interface Link {
	groupItems: GroupItem[],
	selected: boolean,
	responseReview?: UserResponseReview
}

enum CurrentMatchingStateEnum {
	Initial,
	ItemSelected,
	ItemsLinked
}

@UntilDestroy()
@Component({
	selector: 'app-match-case-form',
	templateUrl: './match-case-form.component.html',
	styleUrls: ['./match-case-form.component.less'],
	animations: [
		trigger('fade',
			[
				state("selected", style({ opacity: 1 })),
				state("not-selected", style({ opacity: 0.3 })),
				state("shown", style({ opacity: 1 })),
				transition("selected <=> not-selected", animate('200ms ease-out')),
				transition("void <=> selected", animate('200ms ease-out')),
				transition("void <=> shown", animate('200ms ease-out')),
			])
	]
})
export class MatchCaseFormComponent implements OnInit {

	processingRequest: boolean = false;

	@Input('case') case!: MatchChoiceVariantCase;
	@Input('stage') stage: UserResponseStageEnum = UserResponseStageEnum.Initital;

	UserResponseStageEnum = UserResponseStageEnum;

	groupOne: GroupItem[] = [];
	groupTwo: GroupItem[] = [];

	linked: Link[] = [];

	state: CurrentMatchingStateEnum = CurrentMatchingStateEnum.Initial;

	readonly form = new FormGroup({
		caseTypeId: new FormControl<CaseTypeEnum>(CaseTypeEnum.MatchChoiceVariantCase, [Validators.required]),
		caseId: new FormControl<number | null>(null, [Validators.required])
	});

	constructor(
		private readonly _caseService: CaseService,
		private readonly _interractionService: CaseInteractionService
	) { }

	getGroup(item: GroupItem): GroupItem[] {
		let element = this.groupOne.find(e => e == item);
		if (element) {
			return this.groupOne;
		}
		else {
			return this.groupTwo;
		}
	}

	getInversedGroup(item: GroupItem): GroupItem[] {
		let element = this.groupOne.find(e => e == item);
		if (element) {
			return this.groupTwo;
		}
		else {
			return this.groupOne;
		}
	}

	onSelect(item: GroupItem) {

		if (this.stage == UserResponseStageEnum.Review) {
			return;
		}

		if (item.selected) {
			return;
		}

		item.selected = true;

		this.linked.forEach(e => {
			e.selected = false;
		});

		let selectedGroup = this.getGroup(item);
		selectedGroup.filter(e => e != item).forEach(e => {
			e.selected = false;
		});

		let inversedGroup = this.getInversedGroup(item);

		let linkedGroup = this.linked.find(e => e.groupItems.indexOf(item) >= 0);
		if (linkedGroup) {
			let linkedItem = linkedGroup.groupItems.filter(e => e != item)[0];
			inversedGroup.filter(e => e != linkedItem).forEach(e => {
				e.selected = false;
			});
			linkedItem.selected = true;
			linkedGroup.selected = true;
			this.state = CurrentMatchingStateEnum.ItemsLinked;
		}
		else {
			if (this.state == CurrentMatchingStateEnum.ItemsLinked) {
				inversedGroup.forEach(e => {
					e.selected = false;
				});
				this.state = CurrentMatchingStateEnum.ItemSelected;
			}
			let corresponding = inversedGroup.find(e => e.selected);
			if (corresponding) {
				this.linked.push({ groupItems: [item, corresponding], selected: true });
				item.linked = true;
				corresponding.linked = true;
				this.state = CurrentMatchingStateEnum.ItemsLinked;
			}
		}
	}

	deleteLink(link: Link) {
		link.groupItems[0].linked = false;
		link.groupItems[1].linked = false;
		this.linked.splice(this.linked.indexOf(link), 1);
		this.groupOne.forEach(e => {
			e.selected = false;
		});
		this.groupTwo.forEach(e => {
			e.selected = false;
		});
	}

	linkState(link: Link): string {
		return link.selected ? "selected" : "not-selected";
	}

	vrl(link: Link): boolean {
		let groupA = this.getGroup(link.groupItems[0]);
		let groupB = this.getGroup(link.groupItems[1]);

		let indexA = groupA.indexOf(link.groupItems[0]);
		let indexB = groupB.indexOf(link.groupItems[1]);

		let indexOne = 0;
		let indexTwo = 0;

		if (groupA == this.groupOne) {
			indexOne = indexA;
			indexTwo = indexB;
		}
		else {
			indexOne = indexB;
			indexTwo = indexA;
		}

		return indexOne > indexTwo;
	}

	linkStyle(link: Link): string {
		let groupA = this.getGroup(link.groupItems[0]);
		let groupB = this.getGroup(link.groupItems[1]);

		let indexA = groupA.indexOf(link.groupItems[0]);
		let indexB = groupB.indexOf(link.groupItems[1]);

		let indexOne = 0;
		let indexTwo = 0;

		if (groupA == this.groupOne) {
			indexOne = indexA;
			indexTwo = indexB;
		}
		else {
			indexOne = indexB;
			indexTwo = indexA;
		}

		let style = `--group-1-num:${indexOne}; --group-2-num:${indexTwo}`;

		return style;
	}

	linksContainerStyle(): string {

		let style = `--block-count:${this.groupOne.length}`;

		return style;
	}

	get formValid(): boolean {
		return this.form.valid && this.groupOne.length == this.linked.length;
	}

	ngOnInit(): void {
		this.onCase();
	}

	ngOnChanges(changes: SimpleChanges) {

		if (changes['case'] && !changes['case'].firstChange) {
			this.onCase();
		}

		if (this.stage == UserResponseStageEnum.Review) {

			let hasMistakes: boolean = false;

			this.linked.forEach((l) => {

				l.selected = true;

				let correctVariant = this.case.correctVariants?.find((c) => {
					return (c.variant.id == l.groupItems[0].variant.id && c.correspondingVariant.id == l.groupItems[1].variant.id)
						|| (c.variant.id == l.groupItems[1].variant.id && c.correspondingVariant.id == l.groupItems[0].variant.id);
				});

				if (correctVariant) {
					l.responseReview = {
						correct: true,
						message: correctVariant.successMessage ?? this.case.successMessage ?? "Правильно"
					};
				}
				else {
					hasMistakes = true;
					l.responseReview = {
						correct: false,
						message: this.case.errorMessage ?? "Ошибка"
					};
				}
			});

			this.groupOne.concat(this.groupTwo).forEach(item => {
				item.selected = true;
				item.responseReview = this.linked.find(e => {
					return this.stage == UserResponseStageEnum.Review && (e.groupItems[0].variant.id == item.variant.id || e.groupItems[1].variant.id == item.variant.id)
				})?.responseReview;
			});

			/* if (!hasMistakes) {
				this.submit();
			} */
		}
	}

	get readonly(): boolean {
		return this.stage == UserResponseStageEnum.Review;
	}

	get moveNextButtonText(): string {
		return this.stage == UserResponseStageEnum.Initital ? "Отправить ответ" : "Дальше";
	}

	onCase() {
		this.form.controls.caseId.setValue(this.case.id);
		
		this.groupOne = [];
		this.groupTwo = [];
		this.linked = [];

		this.state = CurrentMatchingStateEnum.Initial;

		this.case.variants?.filter(e => e.groupNum == MatchChoiceVariantGroupEnum.VariantToMatch)
			.sort((a, b) => {
				if (a.sortIndex === b.sortIndex) {
					return a.id < b.id ? -1 : 1
				} else {
					return a.sortIndex < b.sortIndex ? -1 : 1
				}
			})
			.forEach(e => {
				this.groupOne.push({ variant: e, selected: false, linked: false });
			});

		this.case.variants?.filter(e => e.groupNum == MatchChoiceVariantGroupEnum.VariantToMatchWith)
			.sort((a, b) => {
				if (a.sortIndex === b.sortIndex) {
					return a.id < b.id ? -1 : 1
				} else {
					return a.sortIndex < b.sortIndex ? -1 : 1
				}
			})
			.forEach(e => {
				this.groupTwo.push({ variant: e, selected: false, linked: false });
			});
	}

	submit() {

		if (this.stage == UserResponseStageEnum.Review) {
			this._interractionService.userResponseReviewCompleted.next();
			return;
		}

		if (!this.formValid || this.processingRequest) {
			return;
		}

		this.processingRequest = true;
		this._interractionService.userResponseSending.next();

		let request: CreateMatchVariantUserResponseRequest = this.form.value as CreateMatchVariantUserResponseRequest;
		request.chosenVariants = [];
		
		this.linked.forEach(e => {
			request.chosenVariants.push({
				variantId: e.groupItems.find(i => i.variant.groupNum == MatchChoiceVariantGroupEnum.VariantToMatch)!.variant.id!,
				correspondingVariantId: e.groupItems.find(i => i.variant.groupNum == MatchChoiceVariantGroupEnum.VariantToMatchWith)!.variant.id!
			});
		});

		this._caseService.createUserResponse(request)
			.pipe(
				untilDestroyed(this),
				catchError((e) => {
					this._interractionService.userResponseFailed.next();
					return throwError(() => e);
				}),
				finalize(() => {
					this.processingRequest = false;
					this._interractionService.userResponseProcessed.next();
				})
			)
			.subscribe({
				next: (v: ApiResponse<UserResponse>) => {
					this._interractionService.userResponseSucceeded.next();
				}
			})
	}
}