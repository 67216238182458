<app-loader [loading]="processingRequest" [overlay]="true">
	<div class="wrapper">
		<form [formGroup]="form" (ngSubmit)="submit()" class="flex flex-dir-column gapi-6">
			<div>
				<div class="title">Имя</div>
				<input formControlName="firstName" type="text" class="mti-2">
			</div>
			<div>
				<div class="title">Отчество</div>
				<input formControlName="middleName" type="text" class="mti-2">
			</div>
			<div>
				<div class="title">Фамилия</div>
				<input formControlName="lastName" type="text" class="mti-2">
			</div>
			<app-button [disabled]="!form.valid" type="submit" size="s">Сохранить изменения</app-button>
		</form>
		<img class="close" src="/assets/cross-icon-01.svg" (click)="close()" />
	</div>
</app-loader>