import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, ApiPagedResponse } from '../models/api-response';
import { Article, ArticlePage } from '../models/content/content-models';
import { settings } from '../shared/globals/settings';

@Injectable({
	providedIn: 'root'
})
export class ContentService {

	constructor(private readonly _http: HttpClient) { }

	public getArticleById(id: number): Observable<ApiResponse<Article>> {
		return this._http.get<ApiResponse<Article>>(`${settings.endpoints.gameApiRoot}/api/articles/${id}`);
	}

	public getArticlePageById(id: number): Observable<ApiResponse<ArticlePage>> {
		return this._http.get<ApiResponse<ArticlePage>>(`${settings.endpoints.gameApiRoot}/api/articles/pages/${id}`);
	}

	public getAllArticles(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<Article>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<Article>>(`${settings.endpoints.gameApiRoot}/api/articles`, { params: params });
	}

	public getAllArticlePagesByArticleId(articleId: number): Observable<ApiResponse<ArticlePage[]>> {
		return this._http.get<ApiResponse<ArticlePage[]>>(`${settings.endpoints.gameApiRoot}/api/articles/${articleId}/pages`);
	}
}
