import { Case, MatchChoiceVariantCase, SelectChoiceVariantCase } from "../case/case-models"
import { ChoiceVariantTypeEnum } from "../discriminators"

export enum MatchChoiceVariantGroupEnum {
	VariantToMatch = 0,
	VariantToMatchWith = 1
}

export interface ChoiceVariant {
	choiceVariantTypeId: ChoiceVariantTypeEnum,
	id: number,
	enabled: boolean,
	sortIndex: number,
	title?: string,
	text?: string,
	imageUrl?: string,
}

export interface SelectChoiceVariant extends ChoiceVariant {
	isCorrect: boolean,
	resultMessage?: string,
	rank?: number,
	case: SelectChoiceVariantCase,
	nextCase?: Case
}

export interface MatchChoiceVariant extends ChoiceVariant {
	groupNum: MatchChoiceVariantGroupEnum,
	case: MatchChoiceVariantCase,
}

export interface MatchChoiceCorrectVariant {
	successMessage?: string | null,
	rank?: number | null,

	variant: MatchChoiceVariant,
	correspondingVariant: MatchChoiceVariant
}