import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms'

@Component({
	selector: 'app-val-error',
	templateUrl: './val-error.component.html',
	styleUrls: ['./val-error.component.less']
})
export class ValErrorComponent implements OnInit {
	public form?: FormGroup;
	@Input() controlNameToValidate?: string;
	@Input() align: string = 'right';

	constructor(private readonly _formGroupDirective: FormGroupDirective) {

	}

	ngOnInit(): void {
		this.form = this._formGroupDirective.control;
	}
}
