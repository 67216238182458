import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { CaseDetails } from '../models/case/case-models';
import { CreateUserResponseRequest, UserResponse } from '../models/user-response/user-response';
import { settings } from '../shared/globals/settings';

@Injectable({
	providedIn: 'root'
})
export class CaseService {

	constructor(
		private readonly _http: HttpClient
	) { }

	public getCurrent(storyIdentityCode?: string): Observable<ApiResponse<CaseDetails>> {
		return this._http.get<ApiResponse<CaseDetails>>(`${settings.endpoints.gameApiRoot}/api/cases/current/${(storyIdentityCode ?? '')}`);
	}

	public createUserResponse(request: CreateUserResponseRequest): Observable<ApiResponse<UserResponse>> {
		return this._http.post<ApiResponse<UserResponse>>(`${settings.endpoints.gameApiRoot}/api/cases/${request.caseId}/response`, request);
	}

	public startAssessment(chapterId: number): Observable<ApiResponse<void>> {
		return this._http.patch<ApiResponse<void>>(`${settings.endpoints.gameApiRoot}/api/chapters/${chapterId}/start`, {});
	}

	public ensureAssessmentCompleted(chapterId: number): Observable<ApiResponse<void>> {
		return this._http.patch<ApiResponse<void>>(`${settings.endpoints.gameApiRoot}/api/chapters/${chapterId}/ensurecompleted`, {});
	}

	////////////////// НА ВРЕМЯ ТЕСТИРОВАНИЯ //////////////////
	public deleteAllUserResponses(): Observable<any> {
		return this._http.delete(`${settings.endpoints.gameApiRoot}/api/cases/responses/whipe`);
	}
}