import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { UserBasicInfo } from '../models/user/user-models';
import { OAuthService } from 'angular-oauth2-oidc';
import { CaseService } from '../cases/case.service';
import { RoleClaimValues } from '../auth/identity';

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent{

	userBasicInfo$!: Observable<UserBasicInfo | null>;

	constructor(
		private readonly _userProfileService: UserService,
		private readonly _authService: OAuthService,
		private readonly _caseService: CaseService
	)
	{
		this.userBasicInfo$ = this._userProfileService.basicInfo$;
	}

	isAdmin(user: UserBasicInfo): boolean {
		if (user.roles && user.roles.indexOf(RoleClaimValues.globalAdministrator) >= 0) {
			return true;
		}
		return false;
	}

	whipe() {
		this._caseService.deleteAllUserResponses().subscribe(() => {
			localStorage.removeItem("assessment_stage");
			window.location.reload();
		});		
	}

	logout() {
		this._authService.revokeTokenAndLogout({
			client_id: this._authService.clientId,
			returnTo: this._authService.redirectUri
		}, true);
	}
}