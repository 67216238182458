import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.less']
})
export class ButtonComponent {

	@Output("click") click: EventEmitter<Event> = new EventEmitter<Event>();
	@Input("appearance") appearance: string = "active";
	@Input("size") size: string = "m";
	@Input("disabled") disabled: boolean = false;
	@Input("imgUrl") imgUrl?: string;
	@Input("type") type: string = 'button';

	onClick(event: Event) {
		event.stopPropagation()
		if (!this.disabled) {
			this.click.emit(event);
		}
	}
}
