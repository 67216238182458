<div class="wrapper" [class.dashboard-large]="appearance=='dashboard-large'" [class.dashboard-small]="appearance=='dashboard-small'">
	<div class="list-wrapper" *ngIf="list.length">
		<div class="image-wrapper" *ngFor="let item of list" [matTooltip]="item.achievement.title" matTooltipPosition="above">
			<img [src]="achievementImage(item)" class="image" />
		</div>
	</div>
	<div *ngIf="!list.length" class="no-info">
		У вас пока что нет достижений
	</div>
	<app-button *ngIf="list.length" routerLink="/achievements" appearance="outline" size="s">Все достижения</app-button>
</div>