import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UserService } from '../user.service';
import { UsersByAchievement } from 'src/app/models/user/user-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';

type Appearance = 'profile' | 'dashboard-large' | 'dashboard-small';

@UntilDestroy()
@Component({
  selector: 'app-user-recent-achievements',
  templateUrl: './user-recent-achievements.component.html',
  styleUrls: ['./user-recent-achievements.component.less']
})
export class UserRecentAchievementsComponent implements OnInit {

	@Input() appearance: Appearance = 'profile';
	@Input() limit: number = 5;

	@Output() hasData: EventEmitter<string> = new EventEmitter<string>();

	loadingList = true;
	list: UsersByAchievement[] = [];

	constructor(private readonly _userServices: UserService) {
		
	}

	achievementImage(item: UsersByAchievement): string {
		return item.achievement.imageUrl ?? '/assets/achievement-image-01.svg';
	}

	ngOnInit(): void {
		this.loadingList = true;
		this._userServices.getRecentAchievements(1, this.limit)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.loadingList = false;
				})
			)
			.subscribe(data => {
				this.list = data.data;
				if (this.list?.length > 0) {
					this.hasData.emit(`recentachievements`);
				}
			});
	}
}
