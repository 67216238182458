import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '../../user.service';
import { finalize } from 'rxjs';
import { UpdateUserEmailRequest, User } from 'src/app/models/user/user-models';
import { ApiResponse } from 'src/app/models/api-response';
import { CustomValidatorsService } from 'src/app/shared/validators/custom-validators.service';

export enum EmailChangeStageEnum {
	ReadyForInitialRequest,
	InitialRequestSent,
	ValidationCodeReceived,
	EmailUpdated
}

@UntilDestroy()
@Component({
  selector: 'app-user-email-form',
  templateUrl: './user-email-form.component.html',
  styleUrls: ['./user-email-form.component.less']
})
export class UserEmailFormComponent {

	processingRequest: boolean = false;

	currentEmail!: string;

	stage: EmailChangeStageEnum = EmailChangeStageEnum.ReadyForInitialRequest;
	EmailChangeStageEnum = EmailChangeStageEnum;

	readonly form = new FormGroup({
		email: new FormControl<string | null>(null, {
			validators: [Validators.required, Validators.email],
			asyncValidators: [this._validators.emailAvailable()]
		}),
		validationCode: new FormControl<string | null>(null, [Validators.required])
	});

	constructor(
		private readonly _dialogRef: DialogRef,
		@Inject(DIALOG_DATA) _dialogData: {email: string, stage?: EmailChangeStageEnum, validationCode?: string},
		private readonly _userService: UserService,
		private readonly _validators: CustomValidatorsService
	) {
		this.currentEmail = _dialogData.email;
		if (_dialogData.stage) {
			this.stage = _dialogData.stage;
		}
		if (_dialogData.validationCode) {
			this.form.controls.validationCode.setValue(_dialogData.validationCode);
		}
	}

	get errorMessage(): string | null {
		return this._validators.errorMessage(this.form.controls.email);
	}

	close() {
		this._dialogRef.close(false);
	}

	requestEmailChange() {
		this.processingRequest = true;
		this._userService.requestEmailChange()
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: () => {
					this.stage = EmailChangeStageEnum.InitialRequestSent;
				}
			})		
	}

	submit() {
		if (!this.form.valid || this.processingRequest) {
			return;
		}
		this.processingRequest = true;
		this._userService.updateUserEmail(this.form.value as UpdateUserEmailRequest)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: (v: ApiResponse<User>) => {
					this.stage = EmailChangeStageEnum.EmailUpdated;
					this._userService.refreshBasicInfo();
				}
			})
	}
}
