import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NavigationService } from '../services/navigation.service';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[appBackButton]'
})
export class BackButtonDirective {

	constructor(private readonly _navigationService: NavigationService) {}

	@HostListener("click")
	onClick(): void {
		this._navigationService.back();
	}
}