import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject, finalize, map, startWith, switchMap, tap } from 'rxjs';
import { UserStoryBasicInfo, UserStorySearchMode } from 'src/app/models/user/user-models';
import { UserService } from '../user.service';

type Appearance = 'widget' | 'page';

@UntilDestroy()
@Component({
	selector: 'app-user-stories',
	templateUrl: './user-stories.component.html',
	styleUrls: ['./user-stories.component.less']
})
export class UserStoriesComponent implements OnInit {

	@Input() searchMode: UserStorySearchMode = 'notstarted';
	@Input() appearance: Appearance = 'widget';

	@Output() hasData: EventEmitter<string> = new EventEmitter<string>();
	
	loadingList = true;
	list: UserStoryBasicInfo[] = [];
	@Input() pageSize: number = 3;
	totalRecords = 0;
	currentPage = 1;

	refresher: Subject<void> = new Subject<void>();
	refresher$ = this.refresher.asObservable();

	constructor(private readonly _userServices: UserService) {

	}

	page(pageIndex: number) {
		this.currentPage = pageIndex + 1;
		this.refresher.next();
	}

	get buttonTitle(): string {
		let result = 'Весь список';
		if (this.searchMode == 'finished') {
			return 'История курсов';
		}
		else if (this.searchMode == 'notstarted') {
			return 'Все курсы';
		}
		return result;
	}

	ngOnInit(): void {
		this.refresher$.pipe(
			untilDestroyed(this),
			startWith({}),
			switchMap(() => {
				this.loadingList = true;
				let request$ = null;
				if (this.searchMode == 'finished') {
					request$ = this._userServices.getStoriesFinished(this.currentPage, this.pageSize);
				}
				else if (this.searchMode == 'notstarted') {
					request$ = this._userServices.getStoriesNotStarted(this.currentPage, this.pageSize);
				}
				else {
					request$ = this._userServices.getStoriesInProgress(this.currentPage, this.pageSize);
				}
				return request$
					.pipe(
						untilDestroyed(this),
						finalize(() => {
							this.loadingList = false;
						})
					)
			}),
			map(response => {
				this.totalRecords = response.totalRecords;
				return response.data;
			})
		).subscribe(data => {
			this.list = data;
			if (this.list?.length > 0) {
				this.hasData.emit(`userstories-${this.searchMode}`);	
			}
		});
	}
}
