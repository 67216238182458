<div class="wrapper" *ngIf="currentStory; else noinfo" [routerLink]="['/stories', currentStory.storyId]">
	<div>
		<div class="title mbi-3">
			{{currentStory.storyName}}
		</div>
		<div class="progress-section">
			<div class="progress-wrapper">
				<div class="progress-bar" [style.width.%]="progress">
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #noinfo>
	<div class="no-info">
		Текущий курс не найден
	</div>
</ng-template>