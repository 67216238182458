import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TuiAlertService, TuiNotification } from '@taiga-ui/core';
import { ErrorMessageComponent, ErrorMessageData } from 'src/app/error-message/error-message.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

	constructor(
		@Inject(TuiAlertService) private readonly _alertService: TuiAlertService,
		private readonly _snackbar: MatSnackBar
	) { }

	public alertError(title: string, description?: string) {
		/*this._alertService
			.open(description, { label: title, autoClose: 5000, hasCloseButton: false, status: TuiNotification.Error })
			.subscribe();*/
		this._snackbar.openFromComponent<ErrorMessageComponent, ErrorMessageData>(ErrorMessageComponent, {
			data: {
				title,
				message: description
			},
			duration: 5000,
			verticalPosition: 'top'
		});
	}
}
