export enum CaseTypeEnum {
	SelectChoiceVariantCase = 1,
	MatchChoiceVariantCase = 2,
	WrittenChoiceCase = 3,
	ConversationCase = 4
}

export enum ChoiceVariantTypeEnum {
	SelectChoiceVariant = 1,
	MatchChoiceVariant = 2
}

export enum ChapterTypeEnum {
	OpenWorld = 1,
	LearningStage = 2,
	AssessmentStage = 3
}

export enum AchievementTypeEnum {
	CaseCompletionAchievement = 1,
	ChapterCompletionAchievement = 2,
	PartCompletionAchievement = 3,
	StoryCompletionAchievement = 4
}