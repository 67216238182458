import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export type CheckboxAppearance = 'text' | 'image';
export type CheckboxStatus = 'normal' | 'error' | 'correct';

@Component({
	selector: 'app-checkbox',
	templateUrl: './checkbox.component.html',
	styleUrls: ['./checkbox.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: CheckboxComponent
		}
	]
})
export class CheckboxComponent implements ControlValueAccessor {
	@Input() checked: boolean = false;
	@Input() disabled: boolean = false;
	@Input() readonly: boolean = false;
	@Input() status: CheckboxStatus  = 'normal';
	@Input() appearance: CheckboxAppearance = 'text';
	@Input() imageSrc: string | null | undefined = null;
	@Input() showCheckmark: boolean = true;
	touched: boolean = false;

	onChange = (checked: boolean) => { };

	onTouched  = () => { };

	writeValue(checked: boolean): void {
		this.checked = checked;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	valueChanged(): boolean {
		if (this.readonly) {
			return false;
		}
		if (!this.disabled) {
			this.checked = !this.checked;
			this.markAsTouched();
			this.onChange(this.checked);
		}
		return true;
	}

	markAsTouched() {
		if (!this.touched) {
			this.touched = true;
			this.onTouched();
		}
	}
}
