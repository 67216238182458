import { Component } from '@angular/core';

@Component({
  selector: 'app-notfound-page',
  templateUrl: './notfound-page.component.html',
  styleUrls: ['./notfound-page.component.less']
})
export class NotFoundPageComponent {

}
