<div class="wrapper">
	<div class="progress-wrapper">
		<div>
			Вопрос <span class="case">{{details.casesCompleted + 1}}</span> из <span
				class="case">{{details.casesTotal}}</span>
		</div>
		<div>
			<span class="timer" [class.alert]="secondsLeftProp < 10">{{timeLeft}}</span>
		</div>
	</div>
</div>
