import { Component, Input } from '@angular/core';
import { ContentUploadResult } from 'src/app/models/content-upload-result';
import { UserBasicInfo } from 'src/app/models/user/user-models';
import { UserService } from '../user.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-user-basic-info',
  templateUrl: './user-basic-info.component.html',
  styleUrls: ['./user-basic-info.component.less']
})
export class UserBasicInfoComponent {

	@Input() userBasicInfo!: UserBasicInfo;

	loading: boolean = false;

	constructor(private readonly _userService: UserService) { }

	started() {
		this.loading = true;
	}

	onAvatarUpload(upload: ContentUploadResult) {
		this._userService.updateUserAvatar({ avatarUrl: upload.objectUrl })
			.pipe(
				finalize(() => {
					this.loading = false;
				})
			)
			.subscribe((v) => {
				this._userService.refreshBasicInfo();
			});
	}
}
