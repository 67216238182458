import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, throwError } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { WrittenChoiceCase } from 'src/app/models/case/case-models';
import { CaseTypeEnum } from 'src/app/models/discriminators';
import { UserResponseStageEnum } from 'src/app/models/enumerations';
import { CreateWrittenUserResponseRequest, UserResponse } from 'src/app/models/user-response/user-response';
import { CaseInteractionService } from '../case-interaction.service';
import { CaseService } from '../case.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-written-case-form',
	templateUrl: './written-case-form.component.html',
	styleUrls: ['./written-case-form.component.less']
})
export class WrittenCaseFormComponent implements OnInit {

	processingRequest: boolean = false;

	@Input('case') case!: WrittenChoiceCase;
	@Input('stage') stage: UserResponseStageEnum = UserResponseStageEnum.Initital;

	UserResponseStageEnum = UserResponseStageEnum;

	readonly form = new FormGroup({
		caseTypeId: new FormControl<CaseTypeEnum>(CaseTypeEnum.WrittenChoiceCase, [Validators.required]),
		caseId: new FormControl<number | null>(null, [Validators.required]),
		textValue: new FormControl<string | null>(null, [Validators.required]),
	});

	constructor(
		private readonly _caseService: CaseService,
		private readonly _interractionService: CaseInteractionService
	) { }

	ngOnInit(): void {
		this.onCase();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['case'] && !changes['case'].firstChange) {
			this.onCase();
		}
	}

	get readonly(): boolean {
		return this.stage == UserResponseStageEnum.Review;
	}

	onCase() {
		this.form.controls.caseId.setValue(this.case.id);
		this.form.controls.textValue.setValue(null);
	}

	get moveNextButtonText(): string {
		return this.stage == UserResponseStageEnum.Initital ? "Отправить ответ" : "Дальше";
	}
	
	submit() {

		if (this.stage == UserResponseStageEnum.Review) {
			this._interractionService.userResponseReviewCompleted.next();
			return;
		}

		if (!this.form.valid || this.processingRequest) {
			return;
		}

		this.processingRequest = true;
		this._interractionService.userResponseSending.next();

		let request: CreateWrittenUserResponseRequest = this.form.value as CreateWrittenUserResponseRequest;
		
		this._caseService.createUserResponse(request)
			.pipe(
				untilDestroyed(this),
				catchError((e) => {
					this._interractionService.userResponseFailed.next();
					return throwError(() => e);
				}),
				finalize(() => {
					this.processingRequest = false;
					this._interractionService.userResponseProcessed.next();
				})
			)
			.subscribe({
				next: (v: ApiResponse<UserResponse>) => {
					this._interractionService.userResponseSucceeded.next();
				}
			})
	}
}