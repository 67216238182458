<div class="wrapper">
	<div class="match-choices-wrapper">
		<div class="variants-wrapper">
			<div *ngFor="let item of groupOne; index as i" class="item-outer-wrapper">
				<div class="item-inner-wrapper" (click)="onSelect(item)" [class.linked]="item.linked" [class.selected]="item.selected" [class.error]="stage == UserResponseStageEnum.Review && item.responseReview?.correct == false" [class.correct]="stage == UserResponseStageEnum.Review && item.responseReview?.correct == true">
					<div *ngIf="item.variant.imageUrl; else text" class="image-wrapper">
						<img [src]="item.variant.imageUrl" />
					</div>
					<ng-template #text>
						<div class="text-wrapper">
							<div class="title">
								{{item.variant.title}}
							</div>
							<div class="text">
								{{item.variant.text}}
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
		<div class="links-container" [attr.style]="linksContainerStyle()">
			<div *ngFor="let item of linked; index as i" [@fade]="linkState(item)" class="link-wrapper" [class.vrl]="vrl(item)"
				[class.error]="stage == UserResponseStageEnum.Review && item.responseReview?.correct == false"
				[class.correct]="stage == UserResponseStageEnum.Review && item.responseReview?.correct == true"
				[class.selected]="item.selected" [attr.style]="linkStyle(item)">
				<div class="ctrl-wrapper" *ngIf="item.selected && stage != UserResponseStageEnum.Review" [@fade]="'shown'">
					<app-button (click)="deleteLink(item)" size="s" appearance="icon" imgUrl="/assets/cross-icon-01.svg">
					</app-button>
				</div>
			</div>
		</div>
		<div class="variants-wrapper group-two">
			<div *ngFor="let item of groupTwo; index as i" class="item-outer-wrapper">
				<div class="item-inner-wrapper" (click)="onSelect(item)" [class.linked]="item.linked" [class.selected]="item.selected" [class.error]="stage == UserResponseStageEnum.Review && item.responseReview?.correct == false" [class.correct]="stage == UserResponseStageEnum.Review && item.responseReview?.correct == true">
					<div *ngIf="item.variant.imageUrl; else text" class="image-wrapper">
						<img [src]="item.variant.imageUrl" />
					</div>
					<ng-template #text>
						<div class="text-wrapper">
							<div class="title">
								{{item.variant.title}}
							</div>
							<div class="text">
								{{item.variant.text}}
							</div>
						</div>
					</ng-template>
				</div>
				<ng-container *ngIf="stage == UserResponseStageEnum.Review && item.responseReview?.correct == false">
					<ng-container *ngTemplateOutlet="mistake; context: {error: item.responseReview}"></ng-container>
				</ng-container>
			</div>
		</div>
	</div>
	<app-button [disabled]="!formValid" (click)="submit()" class="mti-7">
		{{moveNextButtonText}}
	</app-button>
</div>
<ng-template #mistake let-error="error">
	<div class="arrow-wrapper">
		<app-arrow-sign orientation="vertical">{{error.message}}</app-arrow-sign>
	</div>
</ng-template>