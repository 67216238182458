import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter, finalize, fromEvent, map, Observable, switchMap, tap } from 'rxjs';
import { ApiResponse } from '../models/api-response';
import { ContentUploadResult } from '../models/content-upload-result';
import { UploadFileTypeEnum } from '../models/enumerations';
import { settings } from '../shared/globals/settings';

@UntilDestroy()
@Component({
	selector: 'app-upload',
	templateUrl: './upload.component.html',
	styleUrls: ['./upload.component.less']
})
export class UploadComponent implements AfterViewInit {

	@ViewChild('fileUpload') fileUploadElement!: ElementRef;

	@Input('accept') requiredFileType?: string;
	@Input('showFileName') showFileName: boolean = false;
	@Input('label') buttonText: string = "Загрузить";
	@Input('type') uploadFileType: UploadFileTypeEnum = UploadFileTypeEnum.UserAvatar;
	@Input('custom') custom: boolean = false;

	@Output('upload') contentUploadResult: EventEmitter<ContentUploadResult> = new EventEmitter<ContentUploadResult>();
	@Output('start') uploadStarted: EventEmitter<void> = new EventEmitter<void>();

	fileName?: string;

	uploadProgress: number | null = null;

	upload$!: Observable<HttpEvent<ApiResponse<ContentUploadResult>>>;

	constructor
		(
			private readonly _httpClient: HttpClient
		) {

	}

	ngAfterViewInit(): void {

		this.upload$ = fromEvent<any>(this.fileUploadElement.nativeElement, 'change')
			.pipe(
				untilDestroyed(this),
				filter(e => {
					return e.target.files!.length > 0;
				}),
				tap(() => {
					this.uploadStarted.emit();
				}),
				map(e => {
					const file: File = e.target.files[0];
					const formData = new FormData();
					formData.append("file", file);
					this.uploadProgress = 1;
					this.fileName = file.name;
					return formData;
				}),
				switchMap(data => {
					let url = `${settings.endpoints.gameApiRoot}/api/files/user-avatar`;
					return this._httpClient.post<ApiResponse<ContentUploadResult>>(url, data,
						{
							reportProgress: true,
							observe: 'events'
						}).pipe(
							finalize(() => {								
								this.reset();
								this.fileUploadElement.nativeElement.value = null;
							})
						);
				})
			);

		this.upload$.subscribe(event => {
			if (event.type == HttpEventType.UploadProgress) {
				this.uploadProgress = Math.round(100 * (event.loaded / event.total!));
			}
			if (event.type == HttpEventType.Response) {
				this.contentUploadResult.emit(event.body?.data);
			}
		});
	}

	reset() {
		this.uploadProgress = null;
	}
}