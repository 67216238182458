<div class="outer-wrapper">
	<app-loader [loading]="loading" [overlay]="true">
		<div class="wrapper">
			Похоже, ты прошел весь маршрут. Хочешь пройти еще раз?
			<div class="pti-3 flex flex-jc-end">
				<app-button (click)="deleteAllUserResponses()" size="s">Да, начать заново</app-button>
			</div>
		</div>
	</app-loader>
</div>

