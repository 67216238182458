import { environment } from 'src/environments/environment';

export const settings = {
	endpoints: {
		appRoot: environment.appRoot,
		authRoot: environment.authRoot,
		authUiRoot: environment.authUiRoot,
		gameApiRoot: environment.gameApiRoot,
		logout: environment.authRoot + "/auth/logout",
	}
}