import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UserStorySearchMode } from 'src/app/models/user/user-models';

@Component({
  selector: 'app-user-stories-page',
  templateUrl: './user-stories-page.component.html',
  styleUrls: ['./user-stories-page.component.less']
})
export class UserStoriesPageComponent implements OnInit {

	searchMode?: UserStorySearchMode;

	constructor(private readonly _route: ActivatedRoute) {
		
	}
	
	ngOnInit(): void {
		this._route.paramMap.subscribe((params: ParamMap) => {
			let mode = params.get("searchMode")?.toLowerCase();
			if (mode) {
				this.searchMode = mode as UserStorySearchMode;
			}
		});
	}

}
