import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { isApiErrorResponse } from 'src/app/models/api-response';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {

	constructor(private readonly _alertService: AlertService) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(request)
			.pipe(
				catchError((e: HttpErrorResponse) => {
					let title = "Ошибка";
					if (e.error && isApiErrorResponse(e.error)) {
						this._alertService.alertError(title, e.error.error.description);
					}
					else {
						this._alertService.alertError(title, e.message);
					}
					return throwError(() => e);
				})
			);
	}
}
