<app-loader [loading]="processingRequest" [overlay]="true">
	<div class="wrapper">
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="title">Фамилия пользователя</div>
			<input formControlName="lastName" type="text" class="mti-2">
			<app-button [disabled]="!form.valid" type="submit" size="s" class="mti-6">Сохранить
				изменения</app-button>
		</form>
		<img class="close" src="/assets/cross-icon-01.svg" (click)="close()" />
	</div>
</app-loader>