import { ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Article, ArticlePage } from 'src/app/models/content/content-models';
import { ContentService } from '../content.service';
import { Observable, finalize, map, tap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

@Component({
	selector: 'app-article-page-slider',
	templateUrl: './article-page-slider.component.html',
	styleUrls: ['./article-page-slider.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticlePageSliderComponent implements OnInit, OnDestroy {

	articleId!: number;

	@ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>
	slider: KeenSliderInstance | null = null

	loadingList = true;

	list$ = new Observable<ArticlePage[]>();

	constructor(
		private readonly _contentServices: ContentService,
		private readonly _sanitizer: DomSanitizer,
		private readonly _dialogRef: DialogRef,
		@Inject(DIALOG_DATA) _dialogData: {articleId: number}
	) {
		this.articleId = _dialogData?.articleId;
	}

	markup(html: string): string {
		return this._sanitizer.bypassSecurityTrustHtml(html) as string;
	}

	bgStyle(item: ArticlePage): string | null {
		let result: string | null = 'none';
		let css = item.backgroundCssStyles;
		let img: string | null = null;
		if (item.backgroundImageUrl) {
			img = `url('${item.backgroundImageUrl}')`;
		}
		if (css || img) {
			result = "--background-image: ";
			if (css) {
				result += ' ' + css;
			}
			if (img) {
				result += ' ' + img;
			}
		}
		return result;
	}

	next() {
		this.slider?.next();
	}

	prev() {
		this.slider?.prev();
	}

	initSlider() {
		this.slider = new KeenSlider(this.sliderRef.nativeElement, {
			slides: {
				spacing: 36
			}
		});
	}

	close() {
		this._dialogRef.close(false);
	}

	ngOnInit(): void {
		this.list$ = this._contentServices.getAllArticlePagesByArticleId(this.articleId)
			.pipe(
				tap(() => {
					setTimeout(() => {
						this.initSlider();
					})
				}),
				finalize(() => {
					this.loadingList = false;
				}),
				map(v => (v.data))
			);
	}

	ngOnDestroy(): void {
		if (this.slider) {
			this.slider.destroy();
		}
	}
}