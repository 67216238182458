<app-loader [loading]="processingRequest" [overlay]="true">
	<div class="wrapper">
		<ng-container [ngSwitch]="stage">
			<div *ngSwitchCase="EmailChangeStageEnum.ReadyForInitialRequest">
				<div class="main-title">На адрес</div>
				<div class="email">{{currentEmail}}</div>
				<div class="mti-3">будет отправлено письмо с инструкциями по изменению E-mail</div>
				<app-button (click)="requestEmailChange()" size="s" class="mti-6">
					Изменить E-mail
				</app-button>
			</div>
			<div *ngSwitchCase="EmailChangeStageEnum.InitialRequestSent">
				<div class="main-title">На адрес</div>
				<div class="email">{{currentEmail}}</div>
				<div class="mti-3">отправлено письмо с инструкциями по изменению E-mail</div>
			</div>
			<form [formGroup]="form" (ngSubmit)="submit()" *ngSwitchCase="EmailChangeStageEnum.ValidationCodeReceived">
				<div class="main-title">Изменение E-mail</div>
				<div class="title">Введите новый E-mail</div>
				<input formControlName="email" type="text" class="mti-2">
				<app-val-error controlNameToValidate="email">{{errorMessage}}</app-val-error>
				<app-button [disabled]="!form.valid" type="submit" size="s" class="mti-2">Изменить E-mail</app-button>
			</form>
			<div *ngSwitchCase="EmailChangeStageEnum.EmailUpdated">
				<div class="main-title">E-mail успешно изменен</div>
				<div class="email">{{this.form.controls.email.value}}</div>
				<div class="mti-3">Теперь это ваш новый E-mail</div>
				<app-button [disabled]="!form.valid" (click)="close()" size="s" class="mti-6">Спасибо</app-button>
			</div>
		</ng-container>
		
		<img class="close" src="/assets/cross-icon-01.svg" (click)="close()" />
	</div>
</app-loader>