import { Component } from '@angular/core';

@Component({
  selector: 'app-story-notfound',
  templateUrl: './story-notfound.component.html',
  styleUrls: ['./story-notfound.component.less']
})
export class StoryNotfoundComponent {
	get linkExample(): string {
		return `${window.location.origin}/stories/aK5STm0ytnckMwMhtWCxAQ`;
	}
}
