import { Buffer } from "buffer";

export class ClaimTypes {
	static role = 'role';
}

export class RoleClaimValues {
	static globalAdministrator = 'global-administrator';
}

export class JWTManager {
	static getClaimValues(token: string, claimType: string): string[] {
		const result: string[] = [];
		
		const parts = token.split(".");
		const payload = JSON.parse(Buffer.from(parts[1], 'base64').toString());

		let claims = payload[claimType];
		if (claims) {
			if (Array.isArray(claims)) {
				result.push(...claims);	
			}
			else {
				result.push(claims);	
			}
		}
		return result;
	}
}