<div class="wrapper">
	<div class="list-wrapper" *ngIf="list.length">
		<div class="image-wrapper" *ngFor="let item of list" [matTooltip]="item.dignity.title"
			matTooltipPosition="above">
			<img [src]="dignityImage(item)" class="image" />
		</div>
	</div>
	<div *ngIf="!list.length" class="no-info">
		У вас пока что нет званий
	</div>
	<app-button *ngIf="list.length" routerLink="/dignities" appearance="outline" size="s">Все звания</app-button>
</div>