<div class="content" [class.overlay]="overlay" [class.loading]="loading" (click)="onClick($event)">
	<ng-content>
	</ng-content>
</div>

<div *ngIf="loading" class="lds-ring-outer">
	<div class="lds-ring-inner"></div>
	<div class="lds-ring-inner"></div>
	<div class="lds-ring-inner"></div>
	<div class="lds-ring-inner"></div>
</div>