<label class="container" [class.disabled]="disabled" [class.readonly] = "readonly" [ngClass]="{'error': status == 'error', 'correct': status == 'correct'}" [class]="'appearance-'+appearance">
	<input type="checkbox" [attr.disabled] = "disabled ? 'disabled' : null" [checked]="checked" (click)="valueChanged()">
	<div class="checkmark" *ngIf="showCheckmark"></div>
	<div class="label">
		<div *ngIf="imageSrc" class="image-wrapper">
			<img [src]="imageSrc" />
		</div>
		<ng-content></ng-content>
	</div>
</label>
