import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Article } from 'src/app/models/content/content-models';
import { ContentService } from '../content.service';
import { Observable, finalize, map, tap } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import { Dialog } from '@angular/cdk/dialog';
import { ArticlePageSliderComponent } from '../article-page-slider/article-page-slider.component';
import { LayoutBreakpointEnum } from 'src/app/models/enumerations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-article-slider',
	templateUrl: './article-slider.component.html',
	styleUrls: ['./article-slider.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArticleSliderComponent implements OnInit, OnDestroy {

	@ViewChild("sliderRef") sliderRef!: ElementRef<HTMLElement>
	slider: KeenSliderInstance | null = null

	@Output() hasData: EventEmitter<string> = new EventEmitter<string>();

	loadingList = true;
	list$ = new Observable<Article[]>();

	media: LayoutBreakpointEnum = LayoutBreakpointEnum.Desktop;

	constructor(
		private readonly _contentServices: ContentService,
		private readonly _sanitizer: DomSanitizer,
		private readonly _dialog: Dialog,
		private readonly _breakpointObserver: BreakpointObserver
	) {
		this._breakpointObserver
			.observe(['(max-width: 480px)'])
			.pipe(untilDestroyed(this))
			.subscribe((state: BreakpointState) => {
				this.media = state.matches ? LayoutBreakpointEnum.Mobile : LayoutBreakpointEnum.Desktop;
				if (this.slider) {
					this.slider.update({
						slides: {
							perView: this.media == LayoutBreakpointEnum.Desktop ? 4 : 2,
							spacing: 12
						}
					});
				}
			});
	}

	markup(html: string): string {
		return this._sanitizer.bypassSecurityTrustHtml(html) as string;
	}

	bgStyle(item: Article): string | null {
		let result: string | null = 'none';
		let css = item.backgroundCssStyles;
		let img: string | null = null;
		if (item.backgroundImageUrl) {
			img = `url('${item.backgroundImageUrl}')`;
		}
		if (css || img) {
			result = "--background-image: ";
			if (css) {
				result += ' ' + css;
			}
			if (img) {
				result += ' ' + img;
			}
		}
		return result;
	}

	next() {
		this.slider?.next();
	}

	prev() {
		this.slider?.prev();
	}

	initSlider() {
		this.slider?.slides
		this.slider = new KeenSlider(this.sliderRef.nativeElement, {
			slides: {
				perView: this.media == LayoutBreakpointEnum.Desktop ? 4 : 2,				
				spacing: 12
			}
		});
	}

	loadArticle(item: Article) {
		const dialogRef = this._dialog.open<boolean>(ArticlePageSliderComponent, {
			panelClass: 'article-dialog',
			backdropClass: 'article-dialog-backdrop',
			data: { articleId: item.id }
		});
	}

	ngOnInit(): void {
		this.list$ = this._contentServices.getAllArticles(1, 10)
			.pipe(
				tap((v) => {
					setTimeout(() => {
						this.initSlider();
					});
					if (v.data?.length > 0) {
						this.hasData.emit(`articles`);
					}
				}),
				finalize(() => {
					this.loadingList = false;
				}),
				map(v => (v.data))
			);
	}

	ngOnDestroy(): void {
		if (this.slider) {
			this.slider.destroy();	
		}
	}
}
