<div class="outer-wrapper" #jopa>
	<div class="wrapper">
		Проверка контента, позиционируем, стараемся
		Проверка контента, позиционируем, стараемся
		Проверка контента, позиционируем, стараемся
		Проверка контента, позиционируем, стараемся
		<div class="pti-3 flex flex-jc-end">
			<app-button (click)="test()" size="s">В путь!</app-button>
		</div>
	</div>
</div>