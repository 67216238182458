<div class="wrapper" [class.image]="hasImage">
	<form [formGroup]="form" (ngSubmit)="submit()" class="flex flex-dir-column gapi-8 flex-ai-start flex-m-ai-center">
		<div class="select-choices-wrapper" formArrayName="chosenVariants">
			<div *ngFor="let variant of case.variants; index as i" class="item-wrapper">
				<mat-checkbox [formControlName]="i" (change)="selected(i)" color="primary">
					<div *ngIf="variant.title" class="mat-body">
						{{variant.title}}
					</div>
					<div *ngIf="variant.text" class="mat-body">
						{{variant.text}}
					</div>
				</mat-checkbox>
			</div>
		</div>
		<div>
			<button type="submit" *ngIf="showButtonNext" mat-raised-button [disabled]="!form.valid"
				color="primary">Отправить ответ</button>
		</div>
	</form>
</div>
<ng-template #mistake let-error="error">
	<div class="arrow-wrapper">
		<app-arrow-sign [orientation]="media == LayoutBreakpointEnum.Desktop ? 'horizontal' : 'vertical'">{{error.message}}</app-arrow-sign>
	</div>
</ng-template>