<div class="wrapper">
	<div class="list-wrapper" *ngIf="currentUser">
		<div class="item-wrapper" *ngFor="let item of list; let index = index">
			<div class="avatar-wrapper">
				<app-user-avatar [userBasicInfo]="item" [size]="'xs'" [hasProgress]="index==currentUserIndex"></app-user-avatar>
			</div>
			<div class="details-wrapper">
				<div class="name">
					{{fullName(item)}}
				</div>
				<div class="rank">
					{{rank(item, index + 1)}}
				</div>
			</div>
		</div>
		<div class="self-wrapper" *ngIf="currentUserIndex == -1">
			<div class="space"></div>
			<div class="item-wrapper">
				<div class="avatar-wrapper">
					<app-user-avatar [userBasicInfo]="currentUser!" [size]="'xs'" [hasProgress]="true"></app-user-avatar>
				</div>
				<div class="details-wrapper">
					<div class="name">
						{{fullName(currentUser!)}}
					</div>
					<div class="rank">
						{{rank(currentUser!, currentUser!.currentRank)}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-button *ngIf="list.length" routerLink="/users" appearance="outline" size="s">Все участники</app-button>
</div>