import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AgeSegment, TimeZone } from '../models/system/system-models';
import { Observable } from 'rxjs';
import { ApiPagedResponse, ApiResponse } from '../models/api-response';
import { settings } from '../shared/globals/settings';

@Injectable({
	providedIn: 'root'
})
export class SystemService {

	constructor(
		private readonly _http: HttpClient
	) {

	}

	public getAllTimeZones(pageNumber: number, pageSize: number): Observable<ApiPagedResponse<TimeZone>> {
		let params = new HttpParams()
			.set('pageNumber', pageNumber)
			.set('pageSize', pageSize);
		return this._http.get<ApiPagedResponse<TimeZone>>(`${settings.endpoints.gameApiRoot}/api/system/timezones`, { params: params });
	}

	public getAllAgeSegments(): Observable<ApiResponse<AgeSegment[]>> {
		return this._http.get<ApiResponse<AgeSegment[]>>(`${settings.endpoints.gameApiRoot}/api/system/agesegments`);
	}
}
