<div class="wrapper" [class.widget]="appearance=='widget'">
	<div class="list-wrapper">
		<div class="item-wrapper" *ngFor="let item of list" [routerLink]="['/stories', item.storyId]">
			<div>
				<div class="title">
					{{item.storyName}}
				</div>
				<div *ngIf="searchMode!='notstarted'" class="details-wrapper">
					<div class="details-item">
						<div>Начало курса</div>
						<div>{{item.startedAt | date:'dd MMMM yyyy'}}</div>
					</div>
					<div *ngIf="searchMode=='finished'" class="details-item">
						<div>Завершение курса</div>
						<div>{{item.finishedAt | date:'dd MMMM yyyy'}}</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="!list.length && appearance=='widget'" class="no-info">
			Курсы не найдены
		</div>
	</div>
	<app-button *ngIf="list.length && appearance=='widget'" [routerLink]="['/userstories', searchMode]"
		appearance="outline" size="s">{{buttonTitle}}</app-button>
	
	<app-paginator *ngIf="appearance!='widget'" [totalRecords]="totalRecords" [pageSize]="pageSize" [currentPage]="currentPage-1"
		(page)="page($event)"></app-paginator>
</div>