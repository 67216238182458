import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, debounceTime, delay, distinctUntilChanged, filter, first, map, of, switchMap, tap, timer } from 'rxjs';
import { UserService } from 'src/app/user/user.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {

	constructor(private readonly _userService: UserService) { }
	
	public selectedInArray(min: number = 1, max?: number): ValidatorFn {
		return (formArray: AbstractControl) => {
			const totalSelected = (formArray as FormArray).controls
				.map(control => control.value)
				.reduce((prev, next) => next ? prev + next : prev, 0);
			
			return totalSelected >= min && (max == undefined || totalSelected <= max) ? null : { required: true };
		};
	}

	public emailAvailable(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			return of(control.value)
				.pipe(
					untilDestroyed(this),
					filter(v => v?.length > 0),
					delay(300),
					distinctUntilChanged(),
					switchMap(v => this._userService.checkEmailExists(v)),
					map(v => v.data ? { emailAlreadyExists: true } : null)
				);
		};
	}

	errorMessage(control: AbstractControl): string | null {
		let errors = control.errors;
		if (!errors) {
			return null;
		}
		if (errors['emailAlreadyExists']) {
			return "Такой адрес уже занят";
		}
		if (errors['email']) {
			return "Введите правильный E-mail";
		}
		if (errors['required']) {
			return "Укажите E-mail";
		}
		return null;
	}
}
