import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpdateUserTimeZoneRequest, User } from 'src/app/models/user/user-models';
import { UserService } from '../../user.service';
import { SystemService } from 'src/app/system/system.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { SelectItem } from 'src/app/select/select.component';

@UntilDestroy()
@Component({
  selector: 'app-user-timezone-form',
  templateUrl: './user-timezone-form.component.html',
  styleUrls: ['./user-timezone-form.component.less']
})
export class UserTimeZoneFormComponent implements OnInit {

	processingRequest: boolean = false;

	list: SelectItem[] = [];
	selectedItem?: SelectItem;
	selectedId?: number;

	readonly form = new FormGroup({
		timeZoneId: new FormControl<number | null>(null, [Validators.required])
	});

	constructor(
		private readonly _dialogRef: DialogRef,
		@Inject(DIALOG_DATA) _dialogData: UpdateUserTimeZoneRequest,
		private readonly _userService: UserService,
		private readonly _systemService: SystemService
	) {
		this.selectedId = _dialogData?.timeZoneId;
	}

	close() {
		this._dialogRef.close(false);
	}

	listReady(list: SelectItem[]) {
		if (this.selectedId) {
			this.form.controls.timeZoneId.setValue(this.selectedId);
		}
	}

	ngOnInit(): void {
		this.processingRequest = true;
		this._systemService.getAllTimeZones(1, 200)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: (v) => {
					this.list = v.data.map(e => ({ id: e.id, value: e.displayName }));
				}
			})

	}

	submit() {
		if (!this.form.valid || this.processingRequest) {
			return;
		}
		this.processingRequest = true;
		this._userService.updateUserTimeZone(this.form.value as UpdateUserTimeZoneRequest)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: (v: ApiResponse<User>) => {
					this._dialogRef.close(true);
				}
			})
	}

}
