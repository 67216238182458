<div class="outer-wrapper" [ngStyle]="{'width': radius*2+'px', 'height': radius*2+'px'}">
	<div class="inner-wrapper"
		[ngStyle]="{'width': innerRadius*2+'px', 'height': innerRadius*2+'px', 'border-width': innerStrokeWidth+'px', 'border-color': innerStrokeColor}">
		<ng-content></ng-content>
	</div>
	<svg class="ring-wrapper" [attr.height]="radius*2" [attr.width]="radius*2">
		<circle class="ring" [attr.stroke-width]="ringStrokeWidth"
			[ngStyle]="{'stroke-dasharray': ringStrokeDasharrayStyle, 'stroke-dashoffset': ringStrokeDashoffsetStyle}"
			[attr.stroke]="ringStrokeColor" stroke-linecap="round" fill="transparent" [attr.r]="ringRadius"
			[attr.cx]="radius" [attr.cy]="radius" />
	</svg>
</div>