<app-loader [loading]="processingRequest" [overlay]="true">
	<div class="wrapper">
		<div class="main-title">Дата рождения</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="flex gapi-2">
				<div>
					<div class="title">День</div>
					<div class="mti-2 cwi-15">
						<app-select [list]="daylist" (listReady)="daylistReady($event)" [selectedItem]="selectedDayItem"
							formControlName="day"></app-select>
					</div>
				</div>
				<div>
					<div class="title">Месяц</div>
					<div class="mti-2 cwi-25">
						<app-select [list]="monthlist" (listReady)="monthlistReady($event)"
							[selectedItem]="selectedMonthItem" formControlName="month"></app-select>
					</div>
				</div>
				<div>
					<div class="title">Год</div>
					<div class="mti-2 cwi-20">
						<app-select [list]="yearlist" (listReady)="yearlistReady($event)" [selectedItem]="selectedYearItem"
							formControlName="year"></app-select>
					</div>
				</div>
			</div>
			<app-button [disabled]="!form.valid" type="submit" size="s" class="mti-6">Сохранить</app-button>
		</form>
		<img class="close" src="/assets/cross-icon-01.svg" (click)="close()" />
	</div>
</app-loader>