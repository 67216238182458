import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../user.service';
import { DialogRef } from '@angular/cdk/dialog';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UpdateUserNameRequest, User } from 'src/app/models/user/user-models';
import { finalize } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';

@UntilDestroy()
@Component({
	selector: 'app-user-first-name-form',
	templateUrl: './user-first-name-form.component.html',
	styleUrls: ['./user-first-name-form.component.less']
})
export class UserFirstNameFormComponent {

	processingRequest: boolean = false;

	readonly form = new FormGroup({
		firstName: new FormControl<string | null>(null, [Validators.required]),
		middleName: new FormControl<string | null>(null),
		lastName: new FormControl<string | null>(null, [Validators.required])
	});

	constructor(
		private readonly _dialogRef: DialogRef,
		@Inject(DIALOG_DATA) _dialogData: UpdateUserNameRequest,
		private readonly _userService: UserService
	) {
		this.form.patchValue(_dialogData);
	}

	close() {
		this._dialogRef.close(false);
	}

	submit() {
		if (!this.form.valid || this.processingRequest) {
			return;
		}
		this.processingRequest = true;
		this._userService.updateUserName(this.form.value as UpdateUserNameRequest)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: (v: ApiResponse<User>) => {
					this._dialogRef.close(true);
				}
			})
	}
}