<app-loader [loading]="processingRequest" [overlay]="true">
	<div class="wrapper">
		<div class="main-title">Часовой пояс</div>
		<form [formGroup]="form" (ngSubmit)="submit()">
			<div class="title">Часовой пояс</div>
			<div class="mti-2">
				<app-select [list]="list" (listReady)="listReady($event)" [selectedItem]="selectedItem" formControlName="timeZoneId"></app-select>
			</div>
			<app-button [disabled]="!form.valid" type="submit" size="s" class="mti-6">Сохранить</app-button>
		</form>
		<img class="close" src="/assets/cross-icon-01.svg" (click)="close()" />
	</div>
</app-loader>
