<div class="wrapper">
	<div class="inner-wrapper">
		<div class="column-wrapper">
			<div class="item-wrapper" [class.hidden]="!showArticles">
				<div class="article-slider-wrapper">
					<app-article-slider (hasData)="hasData('articles')"></app-article-slider>
				</div>
			</div>
			<div class="item-wrapper" [class.hidden]="!showCurrentCourse">
				<div class="title">
					Текущий курс
				</div>
				<div class="current-story-wrapper">
					<app-user-current-story (hasData)="hasData('userstories-current')"></app-user-current-story>
				</div>
			</div>
			<div class="item-wrapper" [class.hidden]="!showCoursesNotStarted">
				<div class="title">
					Доступные курсы
				</div>
				<div>
					<app-user-stories (hasData)="hasData('userstories-notstarted')" [searchMode]="'notstarted'" [appearance]="'widget'" [pageSize]="3"></app-user-stories>
				</div>
			</div>
			<div class="item-wrapper" [class.hidden]="!showCoursesFinished">
				<div class="title">
					Пройденные курсы
				</div>
				<div>
					<app-user-stories (hasData)="hasData('userstories-finished')" [searchMode]="'finished'" [appearance]="'widget'" [pageSize]="3"></app-user-stories>
				</div>
			</div>
			<div class="item-wrapper" [class.hidden]="!showCoursesInProgress">
				<div class="title">
					Курсы в процессе прохождения
				</div>
				<div>
					<app-user-stories (hasData)="hasData('userstories-inprogress')" [searchMode]="'inprogress'" [appearance]="'widget'" [pageSize]="3"></app-user-stories>
				</div>
			</div>
		</div>
		<div class="column-wrapper small">
			<div class="item-wrapper">
				<div class="title">
					Рейтинг участников
				</div>
				<div class="users-rank-wrapper">
					<app-users-by-rank-top></app-users-by-rank-top>
				</div>
			</div>
			<div class="item-wrapper" [class.hidden]="!showRecentAchievements">
				<div class="title">
					Текущие достижения
				</div>
				<div class="achievements-wrapper">
					<app-user-recent-achievements (hasData)="hasData('recentachievements')" [appearance]="'dashboard-small'" [limit]="3"></app-user-recent-achievements>
				</div>
			</div>
			<div class="item-wrapper reset">
				<div class="title">
					Удалить прогресс
				</div>
				<div class="users-rank-wrapper">
					<app-loader [loading]="loading" [overlay]="true">
						<app-button class="wide" (click)="deleteAllUserResponses()" size="s" appearance="outline">Да, начать
							заново</app-button>
					</app-loader>
				</div>
			</div>
		</div>
	</div>
	
</div>
