import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject, filter } from 'rxjs';
import { Location } from '@angular/common'

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	private history: string[] = [];

	private navigationEnd: Subject<NavigationEnd> = new Subject<NavigationEnd>();
	navigationEnd$: Observable<NavigationEnd> = this.navigationEnd.asObservable();

	constructor(
		private readonly _router: Router,
		private readonly location: Location
	) { 
		this._router.events
			.pipe(
				filter(e => e instanceof NavigationEnd)
			)
			.subscribe(e => {
				let nav = e as NavigationEnd;
				this.history.push(nav.urlAfterRedirects);
				this.navigationEnd.next(nav);
			});
	}

	isBackAvailable(): boolean {
		return this.history.length > 1;
	}

	back(): void {
		this.history.pop();
		if (this.history.length > 0) {
			this.location.back();
		} else {
			this._router.navigateByUrl("/");
		}
	}
}
