import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssessmentStageChapter } from 'src/app/models/chapter/chapter-models';
import { CaseService } from '../case.service';
import { CaseInteractionService } from '../case-interaction.service';
import { finalize } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-assessment-introduction',
	templateUrl: './assessment-introduction.component.html',
	styleUrls: ['./assessment-introduction.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentIntroductionComponent {

	@Input() chapter!: AssessmentStageChapter;

	processingRequest: boolean = false;

	constructor(
		private readonly _sanitizer: DomSanitizer,
		private readonly _caseService: CaseService,
		private readonly _interractionService: CaseInteractionService
	) {
		
	}

	get bgStyle(): string | null {
		if (this.chapter.introductionBackgroundColor) {
			return `--background-color: ${this.chapter.introductionBackgroundColor}`;
		}
		return null;
	}

	markup(html: string): string {
		return this._sanitizer.bypassSecurityTrustHtml(html) as string;
	}

	submit() {

		if (this.processingRequest) {
			return;
		}

		this.processingRequest = true;

		this._caseService.startAssessment(this.chapter.id)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: () => {
					this._interractionService.assessmentStarted.next(this.chapter);
				}
			})
	}
}