import { Component, Input, SimpleChanges } from '@angular/core';
import { Case } from 'src/app/models/case/case-models';
import { CharacterTypeEnum } from 'src/app/models/enumerations';

@Component({
  selector: 'app-case-phrase-list',
  templateUrl: './case-phrase-list.component.html',
  styleUrls: ['./case-phrase-list.component.less']
})
export class CasePhraseListComponent {
	@Input() case!: Case;

	CharacterTypeEnum = CharacterTypeEnum;

	ngOnChanges(changes: SimpleChanges) {
		if (changes['case']) {
			this.onCase();
		}
	}

	onCase() {

	}
}