import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AssessmentStageChapter } from 'src/app/models/chapter/chapter-models';

@Component({
	selector: 'app-assessment-completion',
	templateUrl: './assessment-completion.component.html',
	styleUrls: ['./assessment-completion.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssessmentCompletionComponent {

	@Input() chapter!: AssessmentStageChapter;

	constructor(
		private readonly _sanitizer: DomSanitizer
	) {

	}

	get bgStyle(): string | null {
		if (this.chapter.completionBackgroundColor) {
			return `--background-color: ${this.chapter.completionBackgroundColor}`;
		}
		return null;
	}

	markup(html: string): string {
		return this._sanitizer.bypassSecurityTrustHtml(html) as string;
	}
}