<app-loader [loading]="loadingList" [overlay]="true">
	<div class="wrapper">
		<div class="arrow prev" (click)="prev()">
			<img src="/assets/prev-icon-01.svg" />
		</div>
		<div class="list-wrapper keen-slider" #sliderRef>
			<div class="article-outer-wrapper keen-slider__slide" *ngFor="let item of list$ | async"
				[style]="bgStyle(item)">
				<div class="article-wrapper" [innerHTML]="markup(item.markup)">
				</div>
			</div>
		</div>
		<div class="arrow next" (click)="next()">
			<img src="/assets/next-icon-01.svg" />
		</div>
		<img class="close" src="/assets/cross-icon-01.svg" (click)="close()" />
	</div>
</app-loader>