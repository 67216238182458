import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-arc-progress',
	templateUrl: './arc-progress.component.html',
	styleUrls: ['./arc-progress.component.less']
})
export class ArcProgressComponent {
	@Input() radius: number = 82;
	@Input() ringStrokeWidth: number = 8;
	@Input() ringStrokeColor: string = '#8BC115';

	@Input() innerStrokeWidth: number = 4;
	@Input() innerStrokeColor: string = '#c0c0c0';

	@Input() progress: number = 0;

	get ringRadius(): number {
		return this.radius - (this.ringStrokeWidth / 2);
	}

	get innerRadius(): number {
		return this.ringRadius + (this.innerStrokeWidth / 2);
	}

	get ringCircumference(): number {
		return this.ringRadius * 2 * Math.PI;
	}

	get ringStrokeDasharrayStyle(): string {
		return `${this.ringCircumference} ${this.ringCircumference}`;
	}

	get ringStrokeDashoffsetStyle(): string {
		const offset = this.ringCircumference - this.progress / 100 * this.ringCircumference;
		return `${offset}`;
	}
}
