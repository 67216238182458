<div class="wrapper">
	<div class="pseudo-button back-button" appBackButton>
		<img src="../../assets/back-icon-01.svg">
		<div>Назад</div>
	</div>
	<div class="item-wrapper basic-info-wrapper" *ngIf="userBasicInfo">
		<app-user-basic-info [userBasicInfo]="userBasicInfo"></app-user-basic-info>
	</div>
	<div class="item-wrapper" [class.hidden]="!showRecentAchievements">
		<app-user-recent-achievements (hasData)="hasData('recentachievements')"></app-user-recent-achievements>
	</div>
	<div class="item-wrapper" [class.hidden]="!showRecentDignities">
		<app-user-recent-dignities (hasData)="hasData('recentdignities')"></app-user-recent-dignities>
	</div>
</div>
