import { Component } from '@angular/core';

@Component({
  selector: 'app-user-achievements-page',
  templateUrl: './user-achievements-page.component.html',
  styleUrls: ['./user-achievements-page.component.less']
})
export class UserAchievementsPageComponent {

}
