import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.less']
})
export class LoaderComponent {
	@Input('loading') loading: boolean = false;
	@Input('overlay') overlay: boolean = false;

	onClick(event: Event) {
		if (this.loading) {
			event.stopPropagation();
		}
	}
}