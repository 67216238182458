<div class="wrapper">
	<div class="avatar-section">
		<app-user-avatar [userBasicInfo]="userBasicInfo" size="l" [hasProgress]="true"></app-user-avatar>
		<div class="avatar-upload-wrapper" [class.hidden]="loading">
			<img src="/assets/edit-icon-01.svg" class="mbi-3" />
			<span class="ta-center">Загрузить<br>изображение</span>
			<app-upload (upload)="onAvatarUpload($event)" (start)="started()" [custom]="true"></app-upload>
		</div>
		<app-loader *ngIf="loading" [loading]="true"></app-loader>
	</div>
	<div class="level">
		Уровень {{userBasicInfo.currentLevel}}
	</div>
	<div class="experience">
		{{userBasicInfo.experiencePointsTotal}} exp
	</div>
	<div class="user-name">
		{{userBasicInfo.firstName}} {{userBasicInfo.lastName}}
	</div>
	<app-button routerLink="/settings" appearance="outline" size="s">Настройки профиля</app-button>
</div>