import { Component } from '@angular/core';

@Component({
  selector: 'app-users-by-rank-page',
  templateUrl: './users-by-rank-page.component.html',
  styleUrls: ['./users-by-rank-page.component.less']
})
export class UsersByRankPageComponent {

}
