<app-loader [loading]="loadingList" [overlay]="true">
	<div class="wrapper">
		<div class="arrow prev" (click)="prev()">
		</div>
		<div class="list-wrapper keen-slider" #sliderRef>
			<div class="article-outer-wrapper keen-slider__slide" *ngFor="let item of list$ | async" [style]="bgStyle(item)">
				<div class="article-wrapper" [innerHTML]="markup(item.thumbnailMarkup)" (click)="loadArticle(item)">
				</div>
			</div>
		</div>
		<div class="arrow next" (click)="next()">
		</div>
	</div>
</app-loader>
