import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpdateUserDateOfBirthRequest, UpdateUserGenderRequest, User } from 'src/app/models/user/user-models';
import { UserService } from '../../user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs';
import { ApiResponse } from 'src/app/models/api-response';
import { SelectItem } from 'src/app/select/select.component';

@UntilDestroy()
@Component({
	selector: 'app-user-dateofbirth-form',
	templateUrl: './user-dateofbirth-form.component.html',
	styleUrls: ['./user-dateofbirth-form.component.less']
})
export class UserDateOfBirthFormComponent {

	processingRequest: boolean = false;

	daylist: SelectItem[] = [];
	monthlist: SelectItem[] = [];
	yearlist: SelectItem[] = [];

	selectedDayItem?: SelectItem;
	selectedDayId?: number;

	selectedMonthItem?: SelectItem;
	selectedMonthId?: number;

	selectedYearItem?: SelectItem;
	selectedYearId?: number;

	readonly form = new FormGroup({
		day: new FormControl<number | null>(null, [Validators.required]),
		month: new FormControl<number | null>(null, [Validators.required]),
		year: new FormControl<number | null>(null, [Validators.required])
	});

	constructor(
		private readonly _dialogRef: DialogRef,
		@Inject(DIALOG_DATA) _dialogData: UpdateUserDateOfBirthRequest,
		private readonly _userService: UserService
	) {
		this.selectedDayId = _dialogData?.day;
		this.selectedMonthId = _dialogData?.month;
		this.selectedYearId = _dialogData?.year;
		
		for (let i = 1; i <= 31; i++) {
			this.daylist.push({ id: i, value: i.toString() });	
		}
		
		this.monthlist.push({ id: 1, value: "Января" });
		this.monthlist.push({ id: 2, value: "Февраля" });
		this.monthlist.push({ id: 3, value: "Марта" });
		this.monthlist.push({ id: 4, value: "Апреля" });
		this.monthlist.push({ id: 5, value: "Мая" });
		this.monthlist.push({ id: 6, value: "Июня" });
		this.monthlist.push({ id: 7, value: "Июля" });
		this.monthlist.push({ id: 8, value: "Августа" });
		this.monthlist.push({ id: 9, value: "Сентября" });
		this.monthlist.push({ id: 10, value: "Октября" });
		this.monthlist.push({ id: 11, value: "Ноября" });
		this.monthlist.push({ id: 12, value: "Декабря" });		
		
		const ageFrom = 10;
		const ageTo = 100;
		const year = (new Date()).getFullYear();

		for (let i = year - ageFrom; i >= year - ageTo; i--) {
			this.yearlist.push({ id: i, value: i.toString() });
		}
		
	}

	close() {
		this._dialogRef.close(false);
	}

	daylistReady(list: SelectItem[]) {
		if (this.selectedDayId)
		this.form.controls.day.setValue(this.selectedDayId);
	}

	monthlistReady(list: SelectItem[]) {
		if (this.selectedMonthId)
		this.form.controls.month.setValue(this.selectedMonthId);
	}

	yearlistReady(list: SelectItem[]) {
		if (this.selectedYearId)
		this.form.controls.year.setValue(this.selectedYearId);
	}

	submit() {
		if (!this.form.valid || this.processingRequest) {
			return;
		}
		this.processingRequest = true;
		this._userService.updateUserDateOfBirth(this.form.value as UpdateUserDateOfBirthRequest)
			.pipe(
				untilDestroyed(this),
				finalize(() => {
					this.processingRequest = false;
				})
			)
			.subscribe({
				next: (v: ApiResponse<User>) => {
					this._dialogRef.close(true);
				}
			})
	}
}