<input type="file" class="file-input" [accept]="requiredFileType" #fileUpload>

<div class="wrapper" *ngIf="!custom; else customLayout">
	<div class="info-wrapper">
		<div class="text-wrapper" *ngIf="!uploadProgress && showFileName">
			{{fileName}}
		</div>
		<div class="progress-bar-wrapper" *ngIf="uploadProgress">
			<mat-progress-bar class="progress-bar" mode="determinate" [value]="uploadProgress"></mat-progress-bar>
		</div>
	</div>
	<div class="button-wrapper">
		<button mat-raised-button color="basic" (click)="fileUpload.click(); $event.preventDefault()">
			{{buttonText}}
		</button>
	</div>
</div>
<ng-template #customLayout>
	<div class="custom-wrapper" (click)="fileUpload.click(); $event.preventDefault()">
		<ng-content>
		</ng-content>
	</div>
</ng-template>