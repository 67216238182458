import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, fromEvent, map, of, switchMap, tap } from 'rxjs';
import { CaseInteractionService } from 'src/app/cases/case-interaction.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@UntilDestroy()
@Component({
	selector: 'app-page-wrapper',
	templateUrl: './page-wrapper.component.html',
	styleUrls: ['./page-wrapper.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageWrapperComponent {
	
	isAuthenticated: boolean = false;
	backgroundImageUrl$ = new Observable<string | null>();
	disableBackgroundImage: boolean = true;
	
	constructor(
		private readonly _interractionService: CaseInteractionService,
		private readonly _navigationService: NavigationService
	)
	{
		this.backgroundImageUrl$ = this._interractionService.caseReceived$.pipe(
			untilDestroyed(this),
			tap((v) => {
				window.scrollTo(0, 0);
			}),
			switchMap((v) => {
				if (v.backgroundImageUrl) {
					let img = new Image();
					img.src = v.backgroundImageUrl;	
					return fromEvent(img, 'load').pipe(map((e => (e.target as HTMLImageElement).src)));
				}				
				return of(null);
			}),
			map(v => {
				if (v) {
					return `--backgroundImageUrl: url(${v})`;	
				}
				else {
					return null;
				}
			})
		);

		this._navigationService.navigationEnd$
			.pipe(
				untilDestroyed(this)
			)
			.subscribe(nav => {
				if (!nav.url.includes('/stories')) {
					this.disableBackgroundImage = true;
				}
				else {
					this.disableBackgroundImage = false;
				}
			});
	}
}