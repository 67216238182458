<div class="wrapper">
	<div class="list-wrapper" *ngIf="currentUser">
		<div class="item-wrapper header">
			<div class="person">Имя участника</div>
			<div class="rank">Место</div>
			<div class="experience">Опыт</div>
		</div>
		<div class="space"></div>
		<div class="item-wrapper" *ngFor="let item of list; let index = index">
			<div class="person">
				<div class="avatar-wrapper">
					<app-user-avatar [userBasicInfo]="item" [size]="'xs'" [hasProgress]="index==currentUserIndex"></app-user-avatar>
				</div>
				<div class="name">
					{{fullName(item)}}
				</div>
			</div>
			<div class="rank">
				{{rank(index + 1) | spacedNumber}}
			</div>
			<div class="experience">
				{{item.experiencePointsTotal | spacedNumber}}
			</div>
		</div>
	</div>
	<app-paginator [totalRecords]="totalRecords" [pageSize]="pageSize" [currentPage]="currentPage-1" (page)="page($event)"></app-paginator>
</div>
