import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, map, Observable, startWith, Subject, switchMap, tap } from 'rxjs';
import { CaseInteractionService } from 'src/app/cases/case-interaction.service';
import { CaseService } from 'src/app/cases/case.service';
import { CaseDetails } from 'src/app/models/case/case-models';
import { AssessmentStageStatusEnum, CaseSelectionStatusEnum } from 'src/app/models/enumerations';
import { AssessmentSignalingService } from 'src/app/signaling/assessment-signaling.service';
import { UserService } from 'src/app/user/user.service';

@UntilDestroy()
@Component({
	selector: 'app-case-page',
	templateUrl: './case-page.component.html',
	styleUrls: ['./case-page.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CasePageComponent implements OnInit, OnDestroy {

	loading: boolean = false;

	@Input() storyIdentityCode!: string;

	caseDetails$!: Observable<CaseDetails | null>;
	
	refresher: Subject<void> = new Subject<void>();

	assessmentStageInitialized: boolean = false;

	chapterId?: number;

	constructor(
		private readonly _caseService: CaseService,
		private readonly _interractionService: CaseInteractionService,
		private readonly _router: Router,
		private readonly _userProfileService: UserService,
		private readonly _assessmentSignalingService: AssessmentSignalingService,
		private readonly _changeDetector: ChangeDetectorRef
	) {
		this._assessmentSignalingService.assessmentCompleted$.pipe(untilDestroyed(this))
			.subscribe((v) => {
				if (v.chapterId == this.chapterId) {
					this.refresher.next();
				}
			});
	}

	async ngOnDestroy() {
		await this._assessmentSignalingService.stopConnection();
	}

	onCaseDetails(data: CaseDetails) {
		if (data.caseSelectionStatusId == CaseSelectionStatusEnum.Failed) {
			this._router.navigateByUrl('/notfound');
		}
		else if (data.caseSelectionStatusId == CaseSelectionStatusEnum.Found) {
			this.chapterId = data.case.chapter.id;
			this._interractionService.caseReceived.next(data.case);
		}

		if (data.assessmentStageDetails?.statusId == AssessmentStageStatusEnum.Started) {
			if (!this.assessmentStageInitialized) {
				this.initializeAssessmentStage();
			}
		}

		if (data.assessmentStageDetails?.statusId == AssessmentStageStatusEnum.Completed) {
			if (this.assessmentStageInitialized) {
				this._assessmentSignalingService.stopConnection();
			}
		}
	}

	initializeAssessmentStage() {
		this.assessmentStageInitialized = true;
		this._assessmentSignalingService.startConnection();
	}

	ngOnInit(): void {
		this._interractionService.userResponseSending$.pipe(untilDestroyed(this)).subscribe(() => {
			this.loading = true;
		});

		this._interractionService.userResponseSucceeded$.pipe(untilDestroyed(this)).subscribe(() => {
			this.refresher.next();
			// Обновляем статистику пользователя
			//this._userProfileService.refreshBasicInfo();
		});

		this._interractionService.userResponseFailed$.pipe(untilDestroyed(this)).subscribe(() => {
			this.loading = false;
		});

		this._interractionService.assessmentStarted$.pipe(untilDestroyed(this)).subscribe(() => {
			this.initializeAssessmentStage();
			this.refresher.next();
		});
		
		this.caseDetails$ = this.refresher.pipe(
			tap(() => {
				this.loading = true;
			}),
			startWith({}),
			switchMap(() => {
				return this._caseService.getCurrent(this.storyIdentityCode)
					.pipe(
						map(v => v.data),
						tap(v => {
							this.onCaseDetails(v);
						}),
						finalize(() => {
							this.loading = false;
						})
					);
			})
		);
	}
}