import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, finalize, throwError } from 'rxjs';
import { CheckboxStatus } from 'src/app/checkbox/checkbox.component';
import { ApiResponse } from 'src/app/models/api-response';
import { SelectChoiceVariantCase } from 'src/app/models/case/case-models';
import { CaseTypeEnum, ChapterTypeEnum } from 'src/app/models/discriminators';
import { LayoutBreakpointEnum, UserResponseStageEnum } from 'src/app/models/enumerations';
import { CreateSelectVariantUserChoiceRequest, CreateSelectVariantUserResponseRequest, UserResponse, UserResponseReview } from 'src/app/models/user-response/user-response';
import { CustomValidatorsService } from 'src/app/shared/validators/custom-validators.service';
import { CaseInteractionService } from '../case-interaction.service';
import { CaseService } from '../case.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-select-case-form',
	templateUrl: './select-case-form.component.html',
	styleUrls: ['./select-case-form.component.less'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectCaseFormComponent implements OnInit {

	processingRequest: boolean = false;

	@Input() case!: SelectChoiceVariantCase;

	media: LayoutBreakpointEnum = LayoutBreakpointEnum.Desktop;
	LayoutBreakpointEnum = LayoutBreakpointEnum;

	showCheckmark: boolean = false;
	showButtonNext: boolean = false;

	readonly form = new FormGroup({
		caseTypeId: new FormControl<CaseTypeEnum>(CaseTypeEnum.SelectChoiceVariantCase, [Validators.required]),
		caseId: new FormControl<number | null>(null, [Validators.required]),
		chosenVariants: new FormArray<any>([], this._customValitators.selectedInArray(1))
	});

	constructor(
		private readonly _caseService: CaseService,
		private readonly _customValitators: CustomValidatorsService,
		private readonly _interractionService: CaseInteractionService,
		private readonly _breakpointObserver: BreakpointObserver
	) { }

	ngOnInit(): void {
		this._breakpointObserver
			.observe(['(max-width: 480px)'])
			.pipe(untilDestroyed(this))
			.subscribe((state: BreakpointState) => {
				this.media = state.matches ? LayoutBreakpointEnum.Mobile : LayoutBreakpointEnum.Desktop;
			});
		
		this.onCase();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['case'] && !changes['case'].firstChange) {
			this.onCase();
		}
	}

	onCase() {
		this.form.controls.chosenVariants.setValidators(null);
		this.form.controls.chosenVariants.updateValueAndValidity();

		this.form.controls.caseId.setValue(this.case.id);
		this.form.controls.chosenVariants.clear();
		this.case.variants?.forEach(e => {
			this.form.controls.chosenVariants.push(new FormControl(false));
		});

		if (!this.case.multipleChoice) {
			this.form.controls.chosenVariants.setValidators([this._customValitators.selectedInArray(1)]);
			this.form.controls.chosenVariants.updateValueAndValidity();	
		}
		else {
			this.form.controls.chosenVariants.setValidators([this._customValitators.selectedInArray(this.case.minSelectedVariants ?? 1, this.case.maxSelectedVariants)]);
			this.form.controls.chosenVariants.updateValueAndValidity();
		}
		
		this.showButtonNext = (this.case.chapter.chapterTypeId != ChapterTypeEnum.OpenWorld);
		this.showCheckmark = (this.case.chapter.chapterTypeId != ChapterTypeEnum.OpenWorld || this.case.multipleChoice);
	}
	

	disabled(i: number): boolean {
		if (this.case.multipleChoice && this.case.maxSelectedVariants) {
			const selected = this.form.controls.chosenVariants.controls.filter((e) => {
				return e.value;
			}).length;
			if (this.form.controls.chosenVariants.controls[i].value == false && selected >= this.case.maxSelectedVariants) {
				return true;
			}
		}
		return false;
	}

	selected(i: number) {
		if (!this.case.multipleChoice && this.form.controls.chosenVariants.controls[i].value == true) {
			this.form.controls.chosenVariants.controls.filter((e, j) => {
				return e.value && i != j;
			}).forEach(e => {
				e.setValue(false);
			});
			
			if (this.case.chapter.chapterTypeId == ChapterTypeEnum.OpenWorld) {
				this.submit();
			}
		}
		if (this.case.multipleChoice) {
			this.form.controls.chosenVariants.controls.forEach((e, j) => {
				if (this.disabled(j)) {
					e.disable();
				}
				else {
					e.enable();
				}
			});
		}
	}

	get hasImage(): boolean {
		return this.case.variants!.some(e => e.imageUrl);
	}

	submit() {

		
		if (!this.form.valid || this.processingRequest) {
			return;
		}

		this.processingRequest = true;
		this._interractionService.userResponseSending.next();

		let request: CreateSelectVariantUserResponseRequest = this.form.value as CreateSelectVariantUserResponseRequest;
		request.chosenVariants = this.form.value.chosenVariants
			.map((v: boolean, i: number) => v ? { variantId: this.case.variants![i].id } : null)
			.filter((v: CreateSelectVariantUserChoiceRequest) => v !== null);

		this._caseService.createUserResponse(request)
			.pipe(
				untilDestroyed(this),
				catchError((e) => {
					this._interractionService.userResponseFailed.next();
					return throwError(() => e);
				}),
				finalize(() => {
					this.processingRequest = false;
					this._interractionService.userResponseProcessed.next();
				})
			)
			.subscribe({
				next: (v: ApiResponse<UserResponse>) => {
					this._interractionService.userResponseSucceeded.next();
				}
			})
	}
}