import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssessmentSignalingService } from 'src/app/signaling/assessment-signaling.service';

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.less']
})
export class TestPageComponent implements OnInit, OnDestroy {

	@ViewChild('jopa') jopa!: ElementRef;

	constructor(private readonly assessmentSignalingService: AssessmentSignalingService) {

	}

	ngOnInit() {
		this.assessmentSignalingService.startConnection();
	}

	async ngOnDestroy() {
		await this.assessmentSignalingService.stopConnection();
	}

	test() {
		this.jopa.nativeElement.style.setProperty('--bg-image', 'url(https://c4.wallpaperflare.com/wallpaper/30/586/460/artwork-fantasy-art-digital-art-forest-wallpaper-preview.jpg)');
	}
}
